<template>
  <div class="container-cell">
    <div class="head">渠道成本</div>
    <div class="info-cell">
      <div class="base-info" v-if="hasChannel">
        <div>
          <p>
            结佣渠道：<strong>{{ commissionDetail.channelName }}</strong>
          </p>
          <p v-if="commissionDetail.status">
            结佣状态：
            <span
              :class="{
                orange: commissionDetail.status == 2,
                green: commissionDetail.status == 3
              }"
              >{{ commissionDetail.statusName }}</span
            >
          </p>
          <p></p>
        </div>
        <div class="mb20">
          <p>
            经纪人姓名：<span>{{ commissionDetail.agentName }}</span>
          </p>
          <p>
            经纪人电话：<span>{{
              commissionDetail.agentPhone | formatPhone
            }}</span>
          </p>
          <p v-if="commissionDetail.reportCode">
            报备带看：<span>{{ commissionDetail.reportCode }}</span
            ><el-button
              class="gray-text-btn"
              type="text"
              @click="showReportDetail"
              >查看</el-button
            >
          </p>
        </div>
      </div>
      <el-table
        v-if="commissionDetail.id || commissionDetail.marketingReward"
        :data="commissionDetail.commissionList"
        border
        class="commission-table"
      >
        <el-table-column label="类型" prop="type" width="150">
        </el-table-column>
        <el-table-column label="结佣点位" width="200">
          <template v-slot="{ row }">
            <span>{{ row.point }}</span>
          </template>
        </el-table-column>
        <el-table-column label="应结金额" width="150">
          <template v-slot="{ row }">
            <span>{{ row.commission | toThousands }}</span>
          </template>
        </el-table-column>
        <el-table-column label="折佣金额" width="150">
          <template v-slot="{ row }">
            <span>{{ row.disCommission | toThousands }}</span>
          </template>
        </el-table-column>
        <el-table-column label="渠道分摊" width="150">
          <template v-slot="{ row }">
            <span>{{ row.marketingAllocation | toThousands }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="`合计${commissionDetail.status == 1 ? '（预）' : ''}`"
          width="150"
        >
          <template v-slot="{ row }">
            <span class="orange">¥{{ row.sumCommission | toThousands }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注说明">
          <template v-slot="{ row }">
            <span>{{ row.note }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-if="logList.length"
        :data="logList"
        border
        class="joint-table"
      >
        <el-table-column
          label="操作人"
          width="150"
          prop="inUserName"
        ></el-table-column>
        <el-table-column
          label="操作时间"
          width="200"
          prop="inDate"
        ></el-table-column>
        <el-table-column label="操作内容">
          <template v-slot="{ row }">
            {{ row.log.label
            }}<span
              v-if="row.log.changeItemList && row.log.changeItemList.length"
              >:{{ row.log.changeItemList | link }}</span
            >
          </template>
        </el-table-column>
      </el-table>
      <div
        class="table-summary"
        v-if="commissionDetail.id || commissionDetail.marketingReward"
      >
        <p>
          合计结佣<template v-if="commissionDetail.status == 1"
            >（预）</template
          >
          ：<span class="orange"
            >¥{{
              (commissionDetail.totalMergeCommission || 0) | toThousands
            }}</span
          >
        </p>
        <p>
          其中，渠道佣金：<span
            >¥{{ commissionDetail.channelCommission | toThousands }}</span
          >
        </p>
        <p>
          营销奖励：<span
            >¥{{ (commissionDetail.marketingReward || 0) | toThousands }}</span
          >
        </p>
        <p v-if="status == 20">
          结佣日期：<span>{{ commissionDetail.inDate }}</span>
        </p>
      </div>
      <div class="tip mt10" v-if="commissionDetail.status == 1">
        预：表示当前结佣数据为预计结佣，仅作为参考，最终以实际申请结佣数据为准；
      </div>
      <div class="btn-group">
        <el-button
          type="info"
          class="blue"
          @click="showPaymentDailog"
          v-if="status < 10 || status == 15"
        >
          {{
            !commissionDetail.status
              ? "创建预结佣"
              : commissionDetail.status == 1
              ? "更新预结佣"
              : "更新佣金"
          }}
        </el-button>
        <el-button
          v-if="commissionDetail.id"
          type="info"
          @click="deleteCommissionPayment"
        >
          删除佣金
        </el-button>
      </div>
    </div>
    <report-detail
      :visible.sync="reportDetailVisible"
      :report-id="activeReportId"
    ></report-detail>
    <!-- 编辑结佣弹窗 -->
    <edit-channel-payment
      :visible.sync="editChannelPaymentVisible"
      :status="status"
      :order-id="orderId"
      :commission-detail="commissionDetail"
    ></edit-channel-payment>
  </div>
</template>

<script>
import ReportDetail from "@/views/distribution/appManage/report/_components/reportDetail";
import EditChannelPayment from "./editPayment";
import {
  getOrderCommissionDetail,
  deleteCommissionPayment
} from "@/api/signedOrder";
export default {
  props: ["status", "orderId", "logList", "commissionDetail", "hasChannel"],
  components: {
    ReportDetail,
    EditChannelPayment
  },
  data() {
    return {
      activeReportId: null, // 当前查看报备详情的报备id
      reportDetailVisible: false,
      editChannelPaymentVisible: false //控制创建渠道结佣弹窗
    };
  },
  methods: {
    // 删除佣金
    deleteCommissionPayment() {
      this.$confirm(`删除后无法恢复结佣记录，确定要删除？`, "删除结佣", {
        type: "warning",
        confirmButtonClass: "el-button--danger"
      })
        .then(async () => {
          const res = await deleteCommissionPayment({ orderId: this.orderId });
          if (res) {
            this.$showSuccess("操作成功");
            this.$root.$emit("signOrderDetail_refresh", {
              refreshTypes: ["detail", "log", "channelAccount"]
            });
          }
        })
        .catch(() => {});
    },
    showReportDetail() {
      this.activeReportId = this.commissionDetail.reportId;
      this.reportDetailVisible = true;
    },
    showPaymentDailog() {
      this.editChannelPaymentVisible = true;
    }
  }
};
</script>

<style scoped lang="scss">
.base-info {
  p {
    color: #999;
    span {
      color: #333;
      &.orange {
        color: $orange;
      }
      &.green {
        color: #52c41a;
      }
    }
  }
  div {
    margin-top: 10px;
    display: flex;
    p {
      width: 33.333333%;
    }
  }
}
::v-deep {
  .commission-table {
    & + .el-table {
      border-top: none;
    }
  }
}
.joint-table {
  margin-top: 0 !important;
}
.gray-text-btn {
  padding: 0 10px;
  color: #999;
}
.btn-group {
  margin-top: 20px;
  .blue {
    color: $primary;
  }
}
strong {
  font-size: 16px;
  color: #333;
}
.tip {
  color: #999;
}
</style>
