<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
    :title="title"
    width="538px"
    class="f-dialog"
    @open="handleOpen"
  >
    <el-form
      class="edit-receipt"
      :model="formData"
      ref="form"
      label-width="100px"
      size="small"
    >
      <div class="form-cell">
        <div class="form-cell-title" style="padding-top:0;">渠道信息</div>
        <el-form-item label="报备带看">
          <el-select
            v-model="formData.reportId"
            filterable
            remote
            reserve-keyword
            placeholder="客户名称、电话后四位"
            :remote-method="getReportList"
            :loading="reportQueryLoading"
            @change="handleReportChange"
          >
            <el-option
              v-for="item in reportList"
              :key="item.id"
              :label="item.customerName + ' ' + item.customerTelephone"
              :value="item.id"
            >
              {{ item.customerName }} {{ item.customerTelephone }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="合作渠道"
          prop="channelName"
          :rules="[$formRules.required()]"
        >
          <el-input
            v-model="formData.channelName"
            placeholder="请输入"
            :disabled="!!formData.reportId"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="经纪人姓名"
          prop="agentName"
          :rules="[$formRules.required()]"
        >
          <el-input
            v-model="formData.agentName"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="经纪人电话"
          prop="agentPhone"
          :rules="[$formRules.required(), $formRules.tel]"
        >
          <el-input
            v-model="formData.agentPhone"
            maxlength="11"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </div>
      <div class="form-cell">
        <div class="form-cell-title">
          <p>基础佣金</p>
          <p class="sub">
            合计结佣<template
              v-if="
                (formData.status == null && status < 10) ||
                  (formData.status == 1 && status != 10)
              "
              >（预）</template
            ><span
              >¥<strong>{{
                $calc.numSub(
                  $calc.accAdd(
                    formData.baseCommission || 0,
                    $calc.numSub(
                      formData.baseCashAward || 0,
                      formData.disCommission || 0
                    )
                  ),
                  formData.marketingAllocation
                ) || 0
              }}</strong></span
            >
          </p>
        </div>
        <el-form-item
          label="结佣点位"
          prop="basePoints"
          :rules="[$formRules.required(), $formRules.m0m100p2]"
        >
          <el-input v-model="formData.basePoints" placeholder="请输入">
            <span slot="append">%</span>
          </el-input>
        </el-form-item>
        <el-form-item
          label="应结佣金"
          prop="baseCommission"
          :rules="[$formRules.required(), $formRules.m0m89p2]"
        >
          <el-input
            v-model="formData.baseCommission"
            placeholder="请输入"
            @input="handlePointInput($event, 'basePoints')"
          >
            <span slot="append">元</span>
          </el-input>
        </el-form-item>
        <el-form-item
          label="现金奖"
          prop="baseCashAward"
          :rules="[$formRules.required(), $formRules.m0m89p2]"
        >
          <el-input v-model="formData.baseCashAward" placeholder="请输入">
            <span slot="append">元</span>
          </el-input>
        </el-form-item>
        <el-form-item
          label="折佣"
          prop="disCommission"
          :rules="[$formRules.required(), $formRules.m0m89p2]"
        >
          <el-input v-model="formData.disCommission" placeholder="请输入">
            <span slot="append">元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="营销分摊" prop="marketingAllocation">
          <el-input v-model="formData.marketingAllocation" disabled>
            <span slot="append">元</span>
          </el-input>
        </el-form-item>
      </div>
      <div class="form-cell">
        <div class="form-cell-title">
          <p>额外佣金</p>
          <p class="sub" v-if="formData.extCommission">
            合计额外佣金<template
              v-if="
                (formData.status == null && status < 10) ||
                  (formData.status == 1 && status != 10)
              "
              >（预）</template
            ><span
              >¥<strong>{{
                $calc.accAdd(
                  formData.extCommission,
                  formData.extCashAward || 0
                ) || 0
              }}</strong></span
            >
          </p>
        </div>
        <el-form-item label="结佣点位" :rules="[$formRules.m0m89p2]">
          <el-input v-model="formData.extPoints" placeholder="请输入">
            <span slot="append">%</span>
          </el-input>
        </el-form-item>
        <el-form-item label="应结佣金" :rules="[$formRules.m0m89p2]">
          <el-input
            v-model="formData.extCommission"
            placeholder="请输入"
            @input="handlePointInput($event, 'extPoints')"
          >
            <span slot="append">元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="现金奖" :rules="[$formRules.m0m89p2]">
          <el-input v-model="formData.extCashAward" placeholder="请输入">
            <span slot="append">元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="其他奖励" prop="extOtherCommission">
          <el-input v-model="formData.extOtherCommission" placeholder="请输入">
            <span slot="append">元</span>
          </el-input>
        </el-form-item>
      </div>
      <div class="form-cell">
        <div class="form-cell-title">
          <p>更多信息</p>
        </div>
        <el-form-item
          label="结佣日期"
          prop="commissionDate"
          :rules="[$formRules.required()]"
        >
          <el-date-picker
            style="width: 100%"
            v-model="formData.commissionDate"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="备注说明" prop="note">
          <el-input type="textarea" v-model="formData.note"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submitForm">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  createCommissionPayment,
  getOrderReportList,
  updateCommissionPayment
} from "@/api/signedOrder";
import { getReportList } from "@/api/distribution";
export default {
  props: ["orderId", "detail", "commissionDetail", "status"],
  data() {
    return {
      formData: {
        agentName: "", // 经纪人姓名 ,
        agentPhone: "", // 经纪人电话 ,
        baseCashAward: "", // 现金奖(基础佣金) ,
        baseCommission: "", // 应结佣金(基础佣金) ,
        baseNote: "", // 备注(基础佣金) ,
        basePoints: "", // 结佣点位(基础佣金) ,
        channelId: "", // 渠道id ,
        channelName: "", // 渠道名 ,
        disCommission: "", // 折佣 ,
        extCashAward: "", // 现金奖(额外佣金) ,
        extCommission: "", // 应结佣金(额外佣金) ,
        extNote: "", // 备注(额外佣金) ,
        extPoints: "", // 结佣点位(额外佣金) ,
        status: null, // 结佣状态 预结佣-结佣中-已结佣
        commissionDate: "", // 结佣日期
        note: "", //备注
        id: "", //,
        orderId: "", //,
        reportId: "", // 报备Id
        totalAmount: "", //成交总额【计算用】
        extOtherCommission: "", // 其他奖励
        baseOtherCommission: "" // 其他奖励
      },
      reportList: [],
      reportQueryLoading: false
    };
  },
  watch: {
    // 计算基础应结佣金
    "formData.basePoints"(val) {
      this.formData.baseCommission = parseInt(
        this.$calc.accMulti(val / 100, this.formData.totalAmount)
      );
    },
    // 计算额外应结佣金
    "formData.extPoints"(val) {
      this.formData.extCommission = parseInt(
        this.$calc.accMulti(val / 100, this.formData.totalAmount)
      );
    }
  },
  computed: {
    title() {
      let str;
      if (!this.commissionDetail.status) {
        str = this.status < 10 ? "创建预结佣" : "申请结佣";
      } else if (this.commissionDetail.status == 1) {
        str = this.status == 10 ? "申请结佣" : "编辑预结佣";
      } else {
        str = "编辑结佣";
      }
      return str;
    }
  },
  methods: {
    // 获取报备列表
    async getReportList(query) {
      this.reportQueryLoading = true;
      const res = await getReportList({
        pageNum: 1,
        pageSize: 10,
        keyword: query,
        estates: [this.formData.estateId]
        // platformIds: [this.$store.state.platformInfo.platformId]
      });
      if (res) {
        this.reportList = res.list;
      }
      this.reportQueryLoading = false;
    },
    async handleOpen() {
      await this.getOrderReportList();
      await this.getReportList();
      // 编辑佣金 字段填充
      if (this.commissionDetail) {
        this.$nextTick(() => {
          Object.assign(this.formData, this.commissionDetail);
        });
      }
    },
    close() {
      this.$emit("update:visible", false);
      this.resetForm();
    },
    // 结佣点位输入
    handlePointInput(val, targetField) {
      clearTimeout(this.pointTimer);
      this.pointTimer = setTimeout(() => {
        this.formData[targetField] = this.$calc.keepDecimal(
          this.$calc.division(val, this.formData.totalAmount) * 100
        );
      }, 500);
    },
    // 获取报备相关信息（渠道信息）
    async getOrderReportList() {
      const res = await getOrderReportList({ orderId: this.orderId });
      if (res) {
        this.formData.disCommission = res.disCommission;
        this.formData.totalAmount = res.totalAmount;
        this.formData.estateId = res.estateId;
        // 如果是申请佣金，设置一些默认值
        if (!this.commissionDetail) {
          this.formData.reportId = res.orderReportId;
          this.formData.agentName = res.agentUser;
          this.formData.agentPhone = res.agentPhone;
          this.formData.channelName = res.mechanismName;
        }
      }
    },
    // 选择报备
    handleReportChange() {
      const { reportId } = this.formData;
      const report = this.reportList.find(item => item.id === reportId);
      if (report) {
        this.formData.agentName = report.reportUserName;
        this.formData.agentPhone = report.reportUserTelephone;
        this.formData.channelName = report.company;
      }
    },
    resetForm() {
      this.$refs.form.resetFields();
    },
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        const res = await (this.commissionDetail.id
          ? updateCommissionPayment
          : createCommissionPayment)({
          ...this.formData,
          status: this.status < 10 ? 1 : 2, // 结佣状态
          preCommission: this.status < 10, // 是否预结佣
          orderId: this.orderId
        });
        if (res) {
          this.$showSuccess("操作成功");
          this.resetForm();
          this.close();
          this.$root.$emit("signOrderDetail_refresh", {
            refreshTypes: ["detail", "log", "channelAccount"]
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.edit-receipt {
  .form-cell {
    .form-cell-title {
      padding: 20px 0 10px 0;
      margin-bottom: 20px;
      font-size: 18px;
      border-bottom: 1px solid $bdColor;
      @include flex-ycb;
      p {
        color: #999;
        span {
          padding-left: 10px;
          color: #333;
        }
        strong {
          font-size: 18px;
        }
      }
      .sub {
        font-size: 14px;
      }
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
    .el-input {
      .el-input-group__append {
        padding: 0 10px;
        background-color: #eee;
      }
    }
  }
}
</style>
