var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-cell"
  }, [_c('div', {
    staticClass: "head"
  }, [_c('p', [_vm._v("订单编号 " + _vm._s(_vm.detail.orderCode))]), _vm.detail.status == 25 ? _c('div', {
    staticClass: "status-progress"
  }, [_c('span', [_vm._v("发起认购")]), _vm.detail.extendStatus == 4 ? _c('span', [_vm._v("已签约")]) : _vm._e(), _c('span', {
    staticClass: "red"
  }, [_vm._v("退款中")])]) : _vm.detail.status == 100 ? _c('div', {
    staticClass: "status-progress invalid"
  }, [_c('span', [_vm._v("发起认购")]), _vm.detail.extendStatus == 1 ? _c('span', [_vm._v("已取消，他签")]) : _vm._e(), _vm.detail.extendStatus == 3 || _vm.detail.extendStatus == 5 ? [_vm.detail.extendStatus == 5 ? _c('span', [_vm._v("已签约")]) : _vm._e(), _c('span', [_vm._v("已退款")]), _c('span', [_vm._v("已取消，退款")])] : _vm._e()], 2) : _c('div', {
    staticClass: "status-progress"
  }, [_vm._l(_vm.statusProgress, function (item, index) {
    return _c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !(index !== _vm.statusProgress.length - 1 && [2, 6, 16].includes(item.status)),
        expression: "\n          !(\n            index !== statusProgress.length - 1 &&\n            [2, 6, 16].includes(item.status)\n          )\n        "
      }],
      key: item.status,
      class: {
        warning: index === _vm.statusProgress.length - 1 && item.status !== 20 && item.status !== 10
      }
    }, [_vm._v(_vm._s(item.text))]);
  }), _vm.detail.isActive === false ? _c('span', {
    staticClass: "danger"
  }, [_vm._v("已作废")]) : _vm.detail.status === 1 ? _c('span', {
    staticStyle: {
      "color": "#999"
    }
  }, [_vm._v("未回款")]) : _vm._e()], 2)]), _c('div', {
    staticClass: "info-cell"
  }, [_c('h3', {
    staticClass: "cell-title"
  }, [_vm._v("房源信息")]), _c('div', {
    staticClass: "cell-box"
  }, [_c('div', {
    staticClass: "cell"
  }, [_c('p', {
    staticClass: "label"
  }, [_vm._v("楼盘/房号")]), _c('h2', [_vm._v(_vm._s(_vm.houseInfo.estateName) + " " + _vm._s(_vm.houseInfo.number))]), _c('p', {
    staticClass: "sub"
  }, [_vm._v(" 建面 " + _vm._s(_vm.houseInfo.buildingSurface) + "㎡ "), _vm.houseInfo.buildingInsideSurface ? _c('span', [_vm._v("套内 " + _vm._s(_vm.houseInfo.buildingInsideSurface) + "㎡")]) : _vm._e()])]), _c('div', {
    staticClass: "cell"
  }, [_c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("计划收款额：")]), _c('span', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.houseInfo.salesPrice)) + "，单价" + _vm._s(_vm._f("toThousands")(_vm.houseInfo.unitSalesPrice)) + "元/㎡")])]), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("控制收款额：")]), _c('span', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.houseInfo.salesReservePrice)) + "，单价" + _vm._s(_vm._f("toThousands")(_vm.houseInfo.unitSalesReservePrice)) + "元/㎡")])]), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("备案价格：")]), _c('span', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.houseInfo.recordPrice)) + "，单价" + _vm._s(_vm._f("toThousands")(_vm.houseInfo.unitRecordPrice)) + "元/㎡")])])]), _c('div', {
    staticClass: "cell"
  }, [_c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("批次地块：")]), _vm.houseInfo.batch || _vm.houseInfo.massif ? _c('span', [_vm._v(_vm._s(_vm._f("link")([_vm.houseInfo.batch, _vm.houseInfo.massif], "，")))]) : _c('span', [_vm._v("-")])]), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("楼栋单元：")]), _vm.houseInfo.building || _vm.houseInfo.unit ? _c('span', [_vm._v(_vm._s(_vm.houseInfo.building)), _vm.houseInfo.unit ? _c('span', [_vm._v("，" + _vm._s(_vm.houseInfo.unit) + "单元")]) : _vm._e()]) : _c('span', [_vm._v("-")])]), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("楼层房号：")]), _c('span', [_vm._v(_vm._s(_vm._f("link")([_vm.houseInfo.floor, _vm.houseInfo.roomNumber], "，")))])])])])]), _vm.detail.status === 1 ? _c('div', {
    staticClass: "info-cell"
  }, [_c('h3', {
    staticClass: "cell-title"
  }, [_vm._v(" 认购信息"), _vm.detail.status === 1 && _vm.orderInfo.overdueDay && _vm.orderInfo.overdueDay <= 2 ? _c('span', {
    staticClass: "red"
  }, [_c('i', {
    staticClass: "el-icon-warning"
  }), _vm.orderInfo.overdueDay >= 0 ? _c('strong', [_vm._v("计划签约还有" + _vm._s(_vm.orderInfo.overdueDay) + "天")]) : _c('strong', [_vm._v("计划签约已超期" + _vm._s(_vm.orderInfo.overdueDay * -1) + "天")])]) : _vm._e()]), _c('div', {
    staticClass: "cell-box"
  }, [_c('div', {
    staticClass: "cell"
  }, [_c('p', {
    staticClass: "label"
  }, [_vm._v("认购总额")]), _c('h2', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.orderInfo.totalAmount)))])]), _c('div', {
    staticClass: "cell"
  }, [_c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("应付定金：")]), _c('span', {
    staticClass: "orange"
  }, [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.orderInfo.depositAmount)))])]), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("认购置业顾问：")]), _c('span', [_vm._v(_vm._s(_vm.orderInfo.customerAuthName))])]), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("认购日期：")]), _c('span', [_vm._v(_vm._s(_vm.orderInfo.orderDate))])]), _c('p', {
    class: {
      red: _vm.detail.status === 1 && _vm.orderInfo.overdueDay < 0
    }
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("计划签约日期： ")]), _c('span', [_vm._v(_vm._s(_vm.orderInfo.signDate))])])]), _c('div', {
    staticClass: "cell"
  }, [_c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("优惠点数：")]), _c('span', [_vm._v(_vm._s(_vm.orderInfo.discountRate))])]), _vm.orderInfo.commissionAmount ? _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("折佣：")]), _c('span', [_vm._v(_vm._s(_vm._f("toThousands")(_vm.orderInfo.commissionAmount)))])]) : _vm._e(), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("溢价：")]), _c('span', {
    class: {
      red: _vm.orderInfo.overflowAmount < 0
    }
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.orderInfo.overflowAmount)))])]), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("总溢价：")]), _c('span', {
    class: {
      red: _vm.orderInfo.overflowTotalAmount < 0
    }
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.orderInfo.overflowTotalAmount)) + " ")])]), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("溢价率： ")]), _c('span', {
    class: {
      red: _vm.orderInfo.overflowTotalRate < 0
    }
  }, [_vm._v(_vm._s(_vm.$calc.accMulti(_vm.orderInfo.overflowTotalRate, 100)) + "%")])])])])]) : _vm._e(), _vm.signInfo ? _c('div', {
    staticClass: "info-cell"
  }, [_c('h3', {
    staticClass: "cell-title"
  }, [_vm._v("签约信息")]), _c('div', {
    staticClass: "cell-box"
  }, [_c('div', {
    staticClass: "cell"
  }, [_c('p', {
    staticClass: "label"
  }, [_vm._v("成交总额")]), _c('h2', {
    staticClass: "orange"
  }, [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.signInfo.totalAmount)))])]), _c('div', {
    staticClass: "cell"
  }, [_c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("签约置业顾问：")]), _c('span', [_vm._v(_vm._s(_vm.signInfo.customerAuthName))])]), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("签约日期：")]), _c('span', [_vm._v(_vm._s(_vm.signInfo.orderDate))])]), _c('br'), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("认购总额：")]), _c('span', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.orderInfo.totalAmount)) + " ")])]), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("应付定金：")]), _c('span', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.orderInfo.depositAmount)))])]), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("认购置业顾问：")]), _c('span', [_vm._v(_vm._s(_vm.orderInfo.customerAuthName))])]), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("认购日期：")]), _c('span', [_vm._v(_vm._s(_vm.orderInfo.orderDate))])]), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("计划签约日期： ")]), _c('span', [_vm._v(_vm._s(_vm.orderInfo.signDate))])])]), _c('div', {
    staticClass: "cell"
  }, [_c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("优惠点数：")]), _c('span', [_vm._v(_vm._s(_vm.signInfo.discountRate))])]), _vm.signInfo.commissionAmount ? _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("折佣：")]), _c('span', [_vm._v(_vm._s(_vm._f("toThousands")(_vm.signInfo.commissionAmount)))])]) : _vm._e(), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("溢价：")]), _c('span', {
    class: {
      red: _vm.signInfo.overflowAmount < 0
    }
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.signInfo.overflowAmount)))])]), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("总溢价：")]), _c('span', {
    class: {
      red: _vm.signInfo.overflowTotalAmount < 0
    }
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.signInfo.overflowTotalAmount)) + " ")])]), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("溢价率： ")]), _c('span', {
    class: {
      red: _vm.signInfo.overflowTotalRate < 0
    }
  }, [_vm._v(_vm._s(_vm.$calc.accMulti(_vm.signInfo.overflowTotalRate, 100)) + "%")])])])])]) : _vm._e(), _c('div', {
    staticClass: "info-cell"
  }, [_c('h3', {
    staticClass: "cell-title"
  }, [_vm._v("客户信息")]), _c('el-table', {
    attrs: {
      "data": _vm.detail.customerList,
      "border": ""
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "客户姓名",
      "prop": "name",
      "width": "150"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "客户电话",
      "width": "200",
      "prop": "phone"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "证件类型",
      "prop": "cardType",
      "width": "150"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "证件号码",
      "prop": "cardNo",
      "width": "250"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "通讯地址",
      "prop": "address"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "操作",
      "width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.showCustomerEdit(row);
            }
          }
        }, [_vm._v("编辑")]), _vm.detail.customerList.length > 1 ? _c('el-button', {
          staticClass: "red",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.delCustomer(row);
            }
          }
        }, [_vm._v("删除")]) : _vm._e()];
      }
    }])
  })], 1), _c('el-button', {
    staticClass: "add-btn",
    attrs: {
      "type": "info"
    },
    on: {
      "click": function click($event) {
        return _vm.showCustomerEdit({});
      }
    }
  }, [_c('i', {
    staticClass: "el-icon-plus"
  }), _vm._v("添加客户")])], 1), _c('div', {
    staticClass: "info-cell"
  }, [_c('h3', {
    staticClass: "cell-title"
  }, [_vm._v("更多信息")]), _vm.detail.marketList && _vm.detail.marketList.length ? [_c('h4', [_vm._v("①、营销活动")]), _c('el-table', {
    staticClass: "mt20",
    attrs: {
      "border": "",
      "data": _vm.detail.marketList
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "奖励对象",
      "prop": "inUserName"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(row.rewardObj == 0 ? "客户奖励" : "渠道经纪人奖励") + " ")];
      }
    }], null, false, 2651180035)
  }), _c('el-table-column', {
    attrs: {
      "label": "奖励内容",
      "prop": "rewardContent"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "我司分摊",
      "prop": "companyShare"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [row.companyShare ? _c('span', [_vm._v(_vm._s(_vm._f("toThousands")(row.companyShare)))]) : _c('span', [_vm._v("-")])];
      }
    }], null, false, 762299797)
  }), _c('el-table-column', {
    attrs: {
      "label": "渠道分摊",
      "prop": "channelShare"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [row.channelShare ? _c('span', [_vm._v(_vm._s(_vm._f("toThousands")(row.channelShare)))]) : _c('span', [_vm._v("-")])];
      }
    }], null, false, 1278240789)
  }), _c('el-table-column', {
    attrs: {
      "label": "营销成本",
      "prop": "marketingCost"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [row.marketingCost ? _c('strong', [_vm._v("￥" + _vm._s(_vm._f("toThousands")(row.marketingCost)))]) : _c('span', [_vm._v("-")])];
      }
    }], null, false, 3177504900)
  })], 1)] : _vm._e(), _c('h4', {
    staticClass: "mt20"
  }, [_vm._v(" " + _vm._s(_vm.detail.marketList && _vm.detail.marketList.length ? "②" : "①") + "、相关附件 ")]), _c('f-upload', {
    staticClass: "mt20",
    attrs: {
      "title": "上传附件图片",
      "default-file-list": _vm.defaultFileList
    },
    on: {
      "on-success": _vm.addAttaches,
      "on-remove": _vm.deleteAttaches
    }
  }), _c('h4', {
    staticClass: "mt20"
  }, [_vm._v(" " + _vm._s(_vm.detail.marketList && _vm.detail.marketList.length ? "③" : "②") + "、订单备注 ")]), _c('el-table', {
    staticClass: "mt20",
    attrs: {
      "border": "",
      "data": _vm.detail.remarkList
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "备注用户",
      "prop": "inUserName",
      "width": "150"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "备注时间",
      "prop": "inDate",
      "width": "250"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "备注内容",
      "prop": "remark"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "操作",
      "width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c('el-button', {
          staticClass: "red",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.delOrderRemark(row);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1), _c('el-input', {
    staticStyle: {
      "margin": "20px 0"
    },
    attrs: {
      "type": "textarea",
      "rows": "4",
      "placeholder": "请输入备注"
    },
    model: {
      value: _vm.remark,
      callback: function callback($$v) {
        _vm.remark = $$v;
      },
      expression: "remark"
    }
  }), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submitRemark
    }
  }, [_vm._v("保存备注")])], 2), _c('div', {}, [_vm.detail.logList && _vm.detail.logList.length ? _c('div', {
    staticClass: "info-cell"
  }, [_c('h3', {
    staticClass: "cell-title"
  }, [_vm._v("操作记录")]), _c('el-table', {
    attrs: {
      "border": "",
      "data": _vm.logList
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "操作用户",
      "prop": "inUserName"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "操作时间",
      "prop": "inDate"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "操作内容"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c('p', {
          staticClass: "operated-content"
        }, [_vm._v(" " + _vm._s(row.log.label)), row.log.changeItemList && row.log.changeItemList.length ? [_vm._v("："), _c('span', {
          domProps: {
            "innerHTML": _vm._s(row.log.changeItemList.join(',').replace(/(https:[^,]+(jpeg|jpg|png|gif))/g, "<a class='blue' href='$1' target='__black'>\n                        <img src='$1' style='display: inline-block;width:50px;height:50px;object-fit:cover' />\n                      </a>"))
          }
        })] : _vm._e()], 2)];
      }
    }], null, false, 2004665290)
  })], 1), _vm.detail.logList.length > 5 ? _c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "prev, pager, next",
      "total": _vm.detail.logList.length,
      "page-size": 5
    },
    on: {
      "current-change": _vm.handlePaginationChange
    }
  }) : _vm._e()], 1) : _vm._e()]), _c('edit-customer', {
    attrs: {
      "visible": _vm.editCustomerVisible,
      "data": _vm.activeCustomer
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editCustomerVisible = $event;
      }
    }
  }), _c('edit-house', {
    attrs: {
      "visible": _vm.editHouseVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editHouseVisible = $event;
      }
    }
  }), _c('edit-subscription', {
    attrs: {
      "visible": _vm.editSubscriptionVisible,
      "order-detail": _vm.detail
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editSubscriptionVisible = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }