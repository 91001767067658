<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
    :title="data && data.id ? '编辑客户' : '新增客户'"
    width="538px"
    class="f-dialog"
    @open="handleOpen"
  >
    <el-form
      :model="formData"
      class="edit-customer"
      label-width="100px"
      size="small"
      ref="form"
    >
      <el-form-item
        label="客户姓名"
        prop="name"
        :rules="[$formRules.required()]"
      >
        <el-input v-model="formData.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item
        label="客户电话"
        prop="phone"
        :rules="[$formRules.required(), $formRules.tel]"
      >
        <el-input
          v-model="formData.phone"
          maxlength="11"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="证件类型" prop="cardType">
        <el-select v-model="formData.cardType">
          <el-option
            v-for="item of certificateTypes"
            :value="item"
            :key="item"
            >{{ item }}</el-option
          >
        </el-select>
      </el-form-item>
      <el-form-item label="证件号码" prop="cardNo">
        <el-input v-model="formData.cardNo" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="通讯地址" prop="address">
        <el-input v-model="formData.address" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { editCustomer } from "@/api/signedOrder";
const formData = {
  address: "", // 地址 ,
  cardNo: "", // 证件号 ,
  cardType: "身份证", // 证件类型 ,
  id: null,
  name: "", // 姓名 ,
  phone: "" // 电话
};
export default {
  props: {
    // 编辑客户的信息
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: this.$deepClone(formData),
      certificateTypes: [
        "身份证",
        "军官证",
        "护照",
        "港澳台居住证",
        "营业执照",
        "其他"
      ]
    };
  },
  watch: {
    data: {
      handler(val) {
        Object.assign(this.formData, val);
        this.formData.cardType = val.cardType || "身份证";
      },
      deep: true
    }
  },
  methods: {
    handleOpen() {
      if (!this.data || !this.data.id) {
        this.formData = this.$deepClone(formData);
        this.$nextTick(() => {
          this.$refs.form.clearValidate();
        });
      }
    },
    close() {
      this.$emit("update:visible", false);
    },
    // 提交表单
    submit() {
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        const params = this.$deepClone(this.formData);
        if (!params.cardNo.trim()) {
          params.cardType = "";
        }
        const res = await editCustomer({
          ...params,
          orderId: this.$route.params.id
        });
        if (res) {
          this.$showSuccess("操作成功");
          this.close();
          this.$root.$emit("signOrderDetail_refresh");
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.edit-customer {
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
}
</style>
