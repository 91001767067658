<template>
  <div class="container-cell">
    <div class="head">结算：甲方房款</div>
    <div class="info-cell">
      <div class="base-info">
        <p>备案价格：<strong>¥1,595,180</strong></p>
        <div>
          <p>已付金额：<span class="orange">¥797,590</span></p>
          <p>未付金额：<span class="red">¥797,590</span></p>
          <p>结算进度：<span>已结50% </span></p>
        </div>
      </div>
      <el-table :data="list" border>
        <el-table-column label="付款方"></el-table-column>
        <el-table-column label="付款单号"></el-table-column>
        <el-table-column label="付款金额">
          <template>
            <p>¥797,590</p>
          </template>
        </el-table-column>
        <el-table-column label="备注说明"> </el-table-column>
        <el-table-column label="状态">
          <template>
            <span>待确认</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-summary">
        <p>合计结款：<span class="red">¥1,595,180</span></p>
        <p>最后结款日期：<span>2021-10-27</span></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [{}]
    };
  },
  created() {},
  methods: {}
};
</script>

<style scoped lang="scss">
.base-info {
  p {
    color: #999;
    strong,
    span {
      color: #333;
    }
    .red {
      color: $red;
    }
    .orange {
      color: $orange;
    }
  }
  div {
    margin-top: 10px;
    display: flex;
    p {
      flex: 1;
    }
  }
}
</style>
