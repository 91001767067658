<template>
  <div class="singed-order-detail">
    <!-- 订单基础信息 -->
    <base-info :detail="detail" :order-id="orderId"></base-info>
    <!-- 我司收款信息 -->
    <company-account
      :order-id="orderId"
      :estate-id="detail.estateId"
      :order-status="detail.status"
      ref="companyAccount"
      :log-list="logsData.paymentLogs || []"
    ></company-account>
    <!-- 甲方收款信息, 收款调整后没做 -->
    <!-- <first-party-account :order-id="orderId"></first-party-account> -->
    <!-- 渠道收款信息 -->
    <channel-account
      v-if="
        detail.hasChannel || (detail.marketList && detail.marketList.length)
      "
      ref="channelAccount"
      :status="detail.status"
      :has-channel="detail.hasChannel"
      :order-id="orderId"
      :detail="detail"
      :log-list="logsData.commissionLogs || []"
      :commission-detail="commissionDetail"
    ></channel-account>
    <!-- 页脚操作按钮 -->
    <div
      class="footer"
      v-if="
        (detail.status === 10 && detail.hasChannel) ||
          detail.status === 15 ||
          detail.status === 1
      "
    >
      <div class="actions">
        <el-button type="info" v-if="detail.status === 1" @click="abolishOrder"
          >订单作废</el-button
        >
        <!-- <el-button
          type="warning"
          v-if="
            (detail.status === 1 && !detail.orderInfo.payedComplete) ||
              (detail.status === 5 && !detail.signInfo.payedComplete)
          "
          @click="editCompanyReceiptVisible = true"
          >创建收款</el-button
        > -->
        <!-- 甲方收款信息, 收款调整后没做 -->
        <!-- <el-button
          type="danger"
          v-if="detail.status === 1"
          @click="editSignVisible = true"
          >发起签约</el-button
        > -->
        <el-button
          type="danger"
          v-if="detail.status === 10 && detail.hasChannel"
          @click="editChannelPaymentVisible = true"
          >申请结佣</el-button
        >
        <el-button
          type="danger"
          @click="editCommissionSettlementVisible = true"
          v-if="detail.status === 15"
          >创建结佣</el-button
        >
        <!--   <el-button
          type="danger"
          @click="confirmChannelPaymentVisible = true"
          v-if="detail.status === 15"
          >确认结佣</el-button
        > -->
      </div>
    </div>
    <!-- 创建收款弹窗 -->
    <edit-company-receipt
      :visible.sync="editCompanyReceiptVisible"
      :order-id="orderId"
      :amount-type="detail.status === 1 ? 1 : 2"
    ></edit-company-receipt>
    <!-- 创建结佣弹窗 -->
    <edit-channel-payment
      :visible.sync="editChannelPaymentVisible"
      :order-id="orderId"
      :detail="detail"
      :commission-detail="commissionDetail"
    ></edit-channel-payment>
    <!-- 创建结佣 明细 替换原来的确认结佣 -->
    <edit-commission-settlement
      :visible.sync="editCommissionSettlementVisible"
      :id="commissionDetail.id"
      :estate-id="detail.estateId"
    >
    </edit-commission-settlement>
    <!-- 甲方收款信息, 收款调整后没做 -->
    <!-- <edit-sign
      :visible.sync="editSignVisible"
      :estate-id="detail.estateId"
      :house-info="detail.houseInfo"
    ></edit-sign> -->
    <!-- 确认结佣 -->
    <confirm-channel-payment
      :visible.sync="confirmChannelPaymentVisible"
      :order-id="orderId"
    ></confirm-channel-payment>
  </div>
</template>

<script>
import BaseInfo from "./_components/baseInfo";
import FirstPartyAccount from "./_components/firstPartyAccount";
import ChannelAccount from "./_components/channelAccount";
import CompanyAccount from "./_components/companyAccount";
import EditCompanyReceipt from "./_components/companyAccount/editReceipt";
import EditChannelPayment from "./_components/channelAccount/editPayment";
import EditCommissionSettlement from "./_components/channelAccount/editCommissionSettlement";
import EditSign from "./_components/sign/edit";
import ConfirmChannelPayment from "./_components/channelAccount/confirmPayment";
import {
  deleteOrder,
  getSignOrderDetail,
  confirmCommissionPayment,
  getOrderLogsList,
  getOrderCommissionDetail
} from "@/api/signedOrder";
export default {
  components: {
    BaseInfo,
    // FirstPartyAccount,
    ChannelAccount,
    CompanyAccount,
    EditCompanyReceipt,
    EditChannelPayment,
    // EditSign,
    ConfirmChannelPayment,
    EditCommissionSettlement
  },
  data() {
    return {
      detail: {
        customerList: [],
        remarkList: [],
        attachs: [],
        houseInfo: {}
      },
      commissionDetail: {},
      logsData: {}, //几个操作记录列表
      orderId: null, // 当前订单id
      editSignVisible: false, //控制创建签约弹窗
      editCompanyReceiptVisible: false, //控制创建收款编辑弹窗
      editChannelPaymentVisible: false, //控制创建渠道结佣弹窗
      confirmChannelPaymentVisible: false, //控制确认结佣弹窗
      editCommissionSettlementVisible: false // 控制创建结佣弹窗
    };
  },
  created() {
    this.orderId = this.$route.params.id;
    this.getSignOrderDetail();
    this.getOrderLogsList();
    this.getOrderCommissionDetail();
    this.handleEventBus();
  },
  methods: {
    handleEventBus() {
      this.$root.$off("signOrderDetail_refresh");
      this.$root.$on("signOrderDetail_refresh", ({ refreshTypes } = {}) => {
        // refreshTypes: 刷新的类型 detail log companyAccount channelAccount
        const types = Array.isArray(refreshTypes)
          ? refreshTypes
          : [refreshTypes];
        for (let type of types) {
          switch (type) {
            case "detail": // 详情
              this.getSignOrderDetail();
              break;
            case "log": // 日志
              this.getOrderLogsList();
              break;
            case "companyAccount": // 公司收款
              this.$refs.companyAccount.refresh();
              break;
            case "channelAccount": // 渠道佣金
              this.getOrderCommissionDetail();
              break;
            default:
              this.getSignOrderDetail();
          }
        }
      });
    },
    // 确认结佣
    async confirmCommissionPayment() {
      this.$confirm(`请确认是否完成结佣，执行已结佣？`, "确认结佣", {
        type: "warning"
      })
        .then(async () => {
          const res = await confirmCommissionPayment({ orderId: this.orderId });
          if (res) {
            this.$showSuccess("操作成功");
            this.getSignOrderDetail();
            this.getOrderLogsList();
            this.$refs.channelAccount.refresh();
          }
        })
        .catch(() => {});
    },
    // 获取订单操作记录列表
    async getOrderLogsList() {
      const res = await getOrderLogsList({ orderId: this.orderId });
      if (res) {
        this.logsData = res;
      }
    },
    // 获取订单详情
    async getSignOrderDetail() {
      const res = await getSignOrderDetail({
        id: this.orderId
      });
      if (res) {
        this.detail = res;
        this.houseInfo = res.houseInfo;
        this.orderInfo = res.orderInfo;
        this.signInfo = res.signInfo;
      }
    },
    // 获取佣金明细
    async getOrderCommissionDetail() {
      const res = await getOrderCommissionDetail({ orderId: this.orderId });
      if (res) {
        if (!Object.entries(res).length) return;
        res.commissionList = [
          {
            type: "基础佣金",
            point: `${res.basePoints || 0}%`,
            commission: res.baseCommission || 0,
            disCommission: `${-res.disCommission || 0}`,
            marketingAllocation: `${-res.marketingAllocation || 0}`,
            sumCommission: res.mergeCommission || 0,
            note: res.baseNote
          },
          {
            type: "现金奖",
            point: "-",
            commission: res.baseCashAward || 0,
            disCommission: "-",
            marketingAllocation: "-",
            sumCommission: res.baseCashAward || 0
          },
          {
            type: "额外佣金",
            point: `${res.extPoints || 0}%`,
            commission: res.extCommission || 0,
            disCommission: "-",
            marketingAllocation: "-",
            sumCommission: res.extCommission || 0,
            note: res.extNote
          },
          {
            type: "额外现金奖",
            point: "-",
            commission: res.extCashAward || 0,
            sumCommission: res.extCashAward || 0
          },
          {
            type: "营销奖励",
            point: "-",
            commission: res.marketingReward || 0,
            sumCommission: res.marketingReward || 0,
            note: `${res.marketingRewardText || ""}（营销成本公司分摊部分）`
          }
        ];
        this.commissionDetail = res;
      }
    },
    // 废除订单
    async abolishOrder(e) {
      this.$confirm(
        `作废后无法恢复，确定要执行作废操作？<br>
        • 该订单将被隐藏，不支持查询；<br>
        • “房源”将重新恢复为“在售”状态；<br>
        • 关联业务都将被作废处理；`,
        "订单作废",
        {
          dangerouslyUseHTMLString: true,
          type: "warning",
          confirmButtonClass: "el-button--danger"
        }
      )
        .then(async () => {
          const res = await deleteOrder({ orderId: this.orderId });
          if (res) {
            this.$showSuccess("作废成功");
            this.$router.replace("/distribution/appManage/signedOrder");
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped lang="scss">
.singed-order-detail {
  padding: 0;
  min-height: 100%;
  background: #fbfbfb;
  ::v-deep {
    .container-cell {
      padding: 0 20px 20px 20px;
      margin-bottom: 20px;
      background: #fff;
      box-shadow: 0 0 5px #eee;
      border-radius: 5px;
      .head {
        @include flex-ycb;
        font-size: 18px;
        font-weight: bold;
        height: 50px;
        border-bottom: 1px solid $bdColor;
      }
      .info-cell {
        margin-top: 20px;
        & + .info-cell {
          margin-top: 40px;
        }
        .cell-title {
          border-left: 4px solid $primary;
          line-height: 16px;
          font-size: 16px;
          padding-left: 10px;
          margin-bottom: 20px;
        }
      }
      .el-table {
        margin-top: 20px;
        th {
          background-color: #f8f8f8;
        }
        th.sub-title {
          background-color: #f7f7fe;
          font-weight: normal;
        }
      }
      .table-summary {
        padding: 10px;
        border: 1px solid #ebeef5;
        border-top: 0;
        background: #f8f8f8;
        @include flex-xe;
        p {
          padding-left: 20px;
          color: #999;
          span {
            color: #333;
            &.orange {
              color: $orange;
            }
          }
        }
      }
    }
  }
  .footer {
    height: 60px;
    .actions {
      position: absolute;
      z-index: 9;
      padding: 0 20px;
      left: 20px;
      right: 0;
      bottom: 0;
      height: 60px;
      background: #fff;
      box-shadow: 0 0 5px #eee;
      border-radius: 5px;
      @include flex-yce;
      ::v-deep {
        .el-button {
          padding-left: 50px;
          padding-right: 50px;
          &:active {
            opacity: 0.8;
          }
          &.el-button--danger {
            background-color: $red;
            border-color: $red;
          }
          &.el-button--warning {
            background-color: $orange;
            border-color: $orange;
          }
          &.el-button--info {
            color: #333;
            background-color: #eee;
            border-color: #eee;
          }
        }
      }
    }
  }
}
</style>
