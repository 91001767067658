var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-cell"
  }, [_c('div', {
    staticClass: "head"
  }, [_vm._v("收/退款记录")]), _vm.receiptPlanList.length ? _c('div', {
    staticClass: "info-cell"
  }, [_c('h3', {
    staticClass: "cell-title"
  }, [_vm._v(" 回款计划 ")]), _vm.receiptSumInfo.houseRemindNames.length ? _c('p', {
    staticClass: "tips"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("回款提醒对象：")]), _vm._v(_vm._s(_vm.receiptSumInfo.houseRemindNames.join("、")) + " ")]) : _vm._e(), _c('el-table', {
    attrs: {
      "data": _vm.receiptPlanList,
      "border": "",
      "row-class-name": _vm.tableRowClassName
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "回款状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [row.paymentStatus === 3 ? _c('p', [_vm._v("已过期")]) : row.paymentStatus === 2 ? _c('p', {
          staticClass: "green"
        }, [_vm._v("已回款")]) : row.paymentCount ? _c('p', {
          staticClass: "orange"
        }, [_vm._v("回款中")]) : _c('p', {
          staticClass: "red"
        }, [_vm._v("未回款")])];
      }
    }], null, false, 16351687)
  }), _c('el-table-column', {
    attrs: {
      "label": "类型",
      "prop": "orderType"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(row.payItemName) + " ")];
      }
    }], null, false, 3393697509)
  }), _c('el-table-column', {
    attrs: {
      "label": "期数",
      "prop": "period"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "收方"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(row.payeeName || "-") + " ")];
      }
    }], null, false, 1942881725)
  }), _c('el-table-column', {
    attrs: {
      "label": "计划回款金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" ¥" + _vm._s(_vm._f("toThousands")(row.totalAmount)) + " ")];
      }
    }], null, false, 1512104827)
  }), _c('el-table-column', {
    attrs: {
      "label": "已收金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [row.payedAmount ? _c('p', {
          staticClass: "orange"
        }, [_vm._v(" ¥" + _vm._s(_vm._f("toThousands")(row.payedAmount)) + " ")]) : _c('p', [_vm._v("-")])];
      }
    }], null, false, 39131846)
  }), _c('el-table-column', {
    attrs: {
      "label": "未收金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [row.unPayedAmount ? _c('p', {
          staticClass: "red"
        }, [_vm._v(" ¥" + _vm._s(_vm._f("toThousands")(row.unPayedAmount)) + " ")]) : _c('p', [_vm._v("-")])];
      }
    }], null, false, 3087688773)
  }), _c('el-table-column', {
    attrs: {
      "label": "计划回款日期"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(row.orderDate || "-") + " ")];
      }
    }], null, false, 3994304424)
  }), _c('el-table-column', {
    attrs: {
      "label": "全额回款日期"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_vm._v(" " + _vm._s(row.payDate || "-") + " ")];
      }
    }], null, false, 2909749166)
  })], 1), _vm.orderStatus !== 1 ? _c('div', {
    staticClass: "table-statistics"
  }, [_c('div', {
    staticClass: "table-statistics gray"
  }, [_c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("应收金额：")]), _vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.receiptSumInfo.totalAmount)) + " ")]), _vm.receiptSumInfo.unCollectionAmount ? _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("未收金额：")]), _c('span', {
    staticClass: "red"
  }, [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.receiptSumInfo.unCollectionAmount)))])]) : _vm._e(), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("收款进度：")]), _vm._v(_vm._s(_vm.receiptSumInfo.collectionCal) + " ")]), _vm.receiptSumInfo.collectionAmount ? _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("已收金额：")]), _c('strong', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.receiptSumInfo.collectionAmount)))])]) : _vm._e()])]) : _vm._e()], 1) : _vm._e(), _vm.receiptRecordList.length ? _c('div', {
    staticClass: "info-cell"
  }, [_c('h3', {
    staticClass: "cell-title"
  }, [_vm._v(" 收/退款记录 ")]), _vm.collectionInfo ? _c('p', {
    staticClass: "tips"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("回款提醒对象：")]), _vm._v(_vm._s(_vm.depositData.remindObject) + " ")]) : _vm._e(), _c('el-table', {
    staticClass: "order-merge-table mt15",
    attrs: {
      "data": _vm.receiptRecordList,
      "row-class-name": _vm.tableRowClassName
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c('el-table-column', {
    attrs: {
      "width": "55",
      "align": "center",
      "prop": "settTypeName",
      "label": "类型"
    }
  }), _c('el-table-column', {
    attrs: {
      "width": "80",
      "align": "center",
      "label": "状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [row.settType == 0 ? [row.orderType == 1 && row.transfered ? _c('span', [_vm._v(" 转为" + _vm._s(row.transferTo === 1 ? "定金" : "房款") + " ")]) : _c('p', {
          style: {
            color: _vm.statusColorMap[row.orderState]
          }
        }, [_vm._v(" " + _vm._s(row.orderState == 0 ? "待确认" : "已确认") + " ")])] : _vm._e(), row.settType == 1 ? [_vm._v(" 已退款 ")] : _vm._e()];
      }
    }], null, false, 2495698421)
  }), _c('el-table-column', {
    attrs: {
      "prop": "receiptNo",
      "label": "收/退单号",
      "width": "150",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.showReceiptDetail(row);
            }
          }
        }, [_vm._v(_vm._s(row.receiptNo))])];
      }
    }], null, false, 3907081833)
  }), _c('el-table-column', {
    attrs: {
      "prop": "houseNumber",
      "label": "房源编号",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var row = _ref11.row;
        return [_vm._v(" " + _vm._s(row.houseNumber) + " ")];
      }
    }], null, false, 645607960)
  }), _c('el-table-column', {
    attrs: {
      "label": "收/退款信息",
      "align": "center"
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "类型",
      "prop": "orderType",
      "align": "center",
      "class-name": "sub-title"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var row = _ref12.row;
        return [_vm._v(" " + _vm._s(row.payItemName) + " ")];
      }
    }], null, false, 3393697509)
  }), _c('el-table-column', {
    attrs: {
      "prop": "payeeType",
      "label": "收/退方",
      "align": "center",
      "class-name": "sub-title"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var row = _ref13.row;
        return [_vm._v(" " + _vm._s(row.payeeName) + " ")];
      }
    }], null, false, 1355108336)
  }), _c('el-table-column', {
    attrs: {
      "prop": "paymentTypeName",
      "label": "支付方式",
      "align": "center",
      "class-name": "sub-title"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var row = _ref14.row;
        return [row.paymentType === 1 ? _c('span', [_vm._v(" POS机"), row.payWayInfo ? _c('span', [_vm._v("/" + _vm._s(row.payWayInfo.name))]) : _vm._e()]) : _c('span', [_vm._v(_vm._s(row.paymentTypeName || "-"))])];
      }
    }], null, false, 2675720999)
  }), _c('el-table-column', {
    attrs: {
      "prop": "paymentTypeName",
      "label": "收/退账户",
      "align": "center",
      "class-name": "sub-title"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var row = _ref15.row;
        return [row.payWayInfo ? [row.payWayInfo.payMethod == 0 ? _c('span', [_vm._v(" " + _vm._s(row.payWayInfo.name) + " ")]) : row.payWayInfo.payMethod == 1 ? _c('span', [_vm._v(" 终端号" + _vm._s(row.payWayInfo.clientNo) + " ")]) : _c('span', [_vm._v("-")])] : [_vm._v("-")]];
      }
    }], null, false, 3205052418)
  }), _c('el-table-column', {
    attrs: {
      "prop": "paymentTransactionNo",
      "label": "交易单号",
      "align": "center",
      "class-name": "sub-title"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var row = _ref16.row;
        return [_c('span', [_vm._v(_vm._s(row.paymentTransactionNo || "-"))])];
      }
    }], null, false, 3252955766)
  })], 1), _c('el-table-column', {
    attrs: {
      "prop": "totalAmount",
      "label": "收/退金额",
      "sortable": "custom",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var row = _ref17.row;
        return [_c('span', {
          class: row.settType == 1 ? 'red' : 'orange',
          style: {
            textDecoration: row.orderType == 1 && row.transfered ? 'line-through' : 'none'
          }
        }, [row.settType == 1 ? _c('span', [_vm._v("- ")]) : _vm._e(), _vm._v("¥" + _vm._s(_vm._f("toThousands")(row.totalAmount)) + " ")])];
      }
    }], null, false, 2431274182)
  }), _c('el-table-column', {
    attrs: {
      "prop": "paymentDate",
      "label": "收/退日期",
      "sortable": "custom",
      "align": "center"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "操作",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.orderType == 1 && scope.row.transfered ? _c('span', [_vm._v(" - ")]) : [scope.row.orderState == 0 ? _c('el-link', {
          staticClass: "mr10",
          attrs: {
            "type": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.confirmReceipt(scope.row);
            }
          }
        }, [_vm._v(" 确认 ")]) : _vm._e(), scope.row.orderState !== 0 ? _c('el-link', {
          staticClass: "mr10",
          attrs: {
            "type": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.showEditReceipt(scope.row);
            }
          }
        }, [_vm._v("编辑")]) : _vm._e(), !(scope.row.orderState == 0 && scope.row.paymentType !== 5) ? _c('el-link', {
          attrs: {
            "type": "danger"
          },
          on: {
            "click": function click($event) {
              scope.row.settType == 0 ? _vm.deleteReceipt(scope.row) : _vm.deleteRefund(scope.row.id);
            }
          }
        }, [_vm._v(" 删除 ")]) : _vm._e(), scope.row.orderState == 0 && scope.row.paymentType !== 5 ? _c('el-dropdown', {
          ref: 'drop-' + scope.$index,
          attrs: {
            "placement": "top-start"
          },
          on: {
            "command": function command($event) {
              return _vm.moreAction($event, scope.row);
            }
          }
        }, [_c('el-link', {
          attrs: {
            "type": "primary"
          }
        }, [_vm._v("更多")]), _c('el-dropdown-menu', {
          attrs: {
            "slot": "dropdown"
          },
          slot: "dropdown"
        }, [_c('el-dropdown-item', {
          attrs: {
            "command": "showEditReceipt"
          }
        }, [_vm._v(" 编辑 ")]), _c('el-dropdown-item', {
          attrs: {
            "command": "deleteReceipt"
          }
        }, [_vm._v(" 删除 ")])], 1)], 1) : _vm._e()]];
      }
    }], null, false, 133761860)
  })], 1), _vm.orderStatus !== 1 ? _c('div', {
    staticClass: "table-statistics"
  }, [_c('div', {
    staticClass: "table-statistics"
  }, [_c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("待确认收款：")]), _c('span', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.receiptSumInfo.unConfirmAmount)))])]), _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("已确认收款：")]), _c('span', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.receiptSumInfo.confirmAmount)))])]), _vm.receiptSumInfo.refundAmount ? _c('p', [_c('span', {
    staticClass: "label"
  }, [_vm._v("已退款金额：")]), _c('span', {
    staticClass: "red"
  }, [_vm._v("-¥" + _vm._s(_vm._f("toThousands")(_vm.receiptSumInfo.refundAmount)))])]) : _vm._e(), _vm.refundFull ? _c('p', {
    staticClass: "refund-summary"
  }, [_vm._v(" 已全额退款 ")]) : _c('p', {
    staticClass: "receipt-summary"
  }, [_vm._v(" 合计实际收款 "), _c('strong', {
    staticClass: "orange"
  }, [_vm._v(" ¥" + _vm._s(_vm._f("toThousands")(_vm.receiptSumInfo.totalActualAmount || 0)))]), _vm._v("， 其中我司 "), _c('strong', [_vm._v(" ¥" + _vm._s(_vm._f("toThousands")(_vm.receiptSumInfo.companyAmount)))])])])]) : _vm._e()], 1) : _vm._e(), _vm.logList.length ? _c('div', {
    staticClass: "info-cell"
  }, [_c('h3', {
    staticClass: "cell-title"
  }, [_vm._v("操作记录")]), _c('el-table', {
    attrs: {
      "data": _vm.currentPageLogList,
      "border": ""
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "操作人",
      "width": "150",
      "prop": "inUserName"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "操作时间",
      "width": "200",
      "prop": "inDate"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "操作内容"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref18) {
        var row = _ref18.row;
        return [_vm._v(" " + _vm._s(row.log.label)), row.log.changeItemList && row.log.changeItemList.length ? _c('span', [_vm._v(":" + _vm._s(_vm._f("link")(row.log.changeItemList)))]) : _vm._e()];
      }
    }], null, false, 1163449040)
  })], 1), _vm.logList.length > 5 ? _c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "prev, pager, next",
      "total": _vm.logList.length,
      "page-size": 5
    },
    on: {
      "current-change": _vm.handlePaginationChange
    }
  }) : _vm._e()], 1) : _vm._e(), _c('edit-company-receipt', {
    attrs: {
      "visible": _vm.editCompanyReceiptVisible,
      "order-id": _vm.orderId,
      "receipt-id": _vm.activeRow.id,
      "success": _vm.handleEditSuccess
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editCompanyReceiptVisible = $event;
      }
    }
  }), _c('edit-company-refund', {
    attrs: {
      "visible": _vm.editCompanyRefundVisible,
      "estateId": _vm.estateId,
      "order-id": _vm.activeRow.orderId,
      "refund-type": _vm.activeRow.orderType == 1 ? '定金' : '房款',
      "id": _vm.activeRow.id,
      "success": _vm.handleEditSuccess
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editCompanyRefundVisible = $event;
      }
    }
  }), _c('financial-detail', {
    ref: "financialDetail",
    attrs: {
      "visible": _vm.financialDetailVisible,
      "id": _vm.activeRow.id,
      "isDetailPage": true
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.financialDetailVisible = $event;
      },
      "sure": function sure($event) {
        return _vm.confirmReceipt(_vm.activeRow);
      },
      "del": function del($event) {
        _vm.activeRow.settType === 0 ? _vm.deleteReceipt(_vm.activeRow) : _vm.deleteRefund(_vm.activeRow.id);
      },
      "edit": function edit($event) {
        return _vm.showEditReceipt(_vm.activeRow);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }