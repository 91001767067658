<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
    title="确认结佣"
    width="538px"
    class="f-dialog"
  >
    <el-form
      class="edit-receipt"
      :model="formData"
      ref="form"
      label-width="100px"
      size="small"
    >
      <div class="form-cell">
        <el-form-item
          label="结佣日期"
          prop="commissionDate"
          :rules="[$formRules.required()]"
        >
          <el-date-picker
            style="width: 100%"
            v-model="formData.commissionDate"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="备注说明">
          <el-input type="textarea" v-model="formData.note"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submitForm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { confirmCommissionPayment } from "@/api/signedOrder";
export default {
  props: ["orderId"],
  data() {
    return {
      formData: {
        commissionDate: this.$time().format("YYYY-MM-DD"),
        note: ""
      }
    };
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    resetForm() {
      this.$refs.form.resetFields();
    },
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        const res = await confirmCommissionPayment({
          ...this.formData,
          orderId: this.orderId
        });
        if (res) {
          this.$showSuccess("操作成功");
          this.resetForm();
          this.close();
          this.$root.$emit("signOrderDetail_refresh", {
            refreshTypes: ["detail", "log", "channelAccount"]
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.edit-receipt {
  .form-cell {
    .form-cell-title {
      padding: 20px 0 10px 0;
      margin-bottom: 20px;
      font-size: 18px;
      border-bottom: 1px solid $bdColor;
      @include flex-ycb;
      p {
        color: #999;
        span {
          padding-left: 10px;
          color: #333;
        }
        strong {
          font-size: 18px;
        }
      }
      .sub {
        font-size: 14px;
      }
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
    .el-input {
      .el-input-group__append {
        padding: 0 10px;
        background-color: #eee;
      }
    }
  }
}
</style>
