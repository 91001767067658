<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
    title="编辑认购"
    width="538px"
    class="f-dialog"
    @open="handleOpen"
  >
    <el-form
      class="edit-sign"
      :model="formData"
      label-width="120px"
      size="small"
      ref="form"
    >
      <el-form-item
        label="成交总额"
        prop="totalAmount"
        :rules="[$formRules.required(), $formRules.m0m89p2]"
      >
        <el-input v-model="formData.totalAmount" placeholder="请输入">
          <span slot="append">元</span>
        </el-input>
      </el-form-item>
      <div class="amount-detail">
        <premium-price-calc :data="premiumPriceCalcData"></premium-price-calc>
      </div>
      <el-form-item label="折佣金额" :rules="[$formRules.m0m69p2]">
        <el-input v-model="formData.commissionAmount" placeholder="请输入">
          <span slot="append">元</span>
        </el-input>
      </el-form-item>
      <el-form-item
        label="应付定金"
        prop="depositAmount"
        :rules="[$formRules.required(), $formRules.m0m69p2]"
      >
        <el-input v-model="formData.depositAmount" placeholder="请输入">
          <span slot="append">元</span>
        </el-input>
      </el-form-item>
      <el-form-item
        label="置业顾问"
        prop="customerAuthId"
        :rules="[$formRules.required()]"
      >
        <el-select filterable v-model="formData.customerAuthId">
          <el-option
            v-for="item of consultantList"
            :value="item.id"
            :label="item.authName"
            :key="item.id"
            >{{ item.authName }}</el-option
          >
        </el-select>
      </el-form-item>
      <el-form-item
        label="认购日期"
        prop="orderDate"
        :rules="[$formRules.required()]"
      >
        <el-date-picker
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          v-model="formData.orderDate"
          @change="handleOrderDateChange"
        ></el-date-picker>
      </el-form-item>
      <el-form-item
        label="计划签约日期"
        prop="signDate"
        :rules="[$formRules.required()]"
      >
        <el-date-picker
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          v-model="formData.signDate"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { editSubscription, getConsultantList } from "@/api/signedOrder";
import PremiumPriceCalc from "../_components/premiumPriceCalc";
export default {
  components: { PremiumPriceCalc },
  props: ["orderDetail"],
  data() {
    return {
      premiumPriceCalcData: {
        transactionPrice: 0, //认购总额
        salesPrice: 0, // 控制收款额
        recordPrice: 0, // 备案总价
        salesReservePrice: 0 // 计划收款额
      },
      formData: {
        commissionAmount: "", // 折佣金额
        customerAuthId: "", // 置业顾问
        customerAuthName: "",
        depositAmount: "", //应付定金
        orderDate: "", //签约日期、
        signDate: "", //计划签约日期
        totalAmount: "" //成交总额
      },
      // 置业顾问列表
      consultantList: []
    };
  },
  watch: {
    "formData.totalAmount"(val) {
      this.premiumPriceCalcData.transactionPrice = val;
    },
    orderDetail(val) {
      Object.assign(this.formData, val.orderInfo);
      Object.assign(this.premiumPriceCalcData, val.houseInfo);
    }
  },
  methods: {
    // 选择认购时间时更新 计划签约时间 【为认购日期的一个礼拜后】
    handleOrderDateChange(val) {
      this.formData.signDate = this.$time(val)
        .add(7, "day")
        .format("YYYY-MM-DD");
    },
    close() {
      this.$emit("update:visible", false);
    },
    handleOpen() {
      this.getConsultantList();
    },
    async getConsultantList() {
      const res = await getConsultantList({
        estateId: this.orderDetail.estateId
      });
      if (res) {
        this.consultantList = res;
      }
    },
    submit() {
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        const res = await editSubscription({
          ...this.formData,
          orderId: this.$route.params.id
        });
        if (res) {
          this.$root.$emit("signOrderDetail_refresh");
          this.close();
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.edit-sign {
  ::v-deep {
    .el-select,
    .el-date-editor {
      width: 100%;
    }
    .el-input {
      .el-input-group__append {
        padding: 0 10px;
        background-color: #eee;
      }
    }
  }
  .amount-detail {
    margin-top: -10px;
    margin-bottom: 10px;
    @include flex-xe2;
    p {
      padding-bottom: 4px;
      color: #999;
      span {
        color: #333;
      }
    }
  }
}
</style>
