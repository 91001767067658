<template>
  <div class="container-cell">
    <div class="head">
      <p>订单编号 {{ detail.orderCode }}</p>
      <!-- 退款中 -->
      <div class="status-progress" v-if="detail.status == 25">
        <span>发起认购</span>
        <!-- 4 签约退款中 -->
        <span v-if="detail.extendStatus == 4">已签约</span>
        <span class="red">退款中</span>
      </div>
      <!-- 已取消 -->
      <div class="status-progress invalid" v-else-if="detail.status == 100">
        <span>发起认购</span>
        <!-- 1 认购它签取消 -->
        <span v-if="detail.extendStatus == 1">已取消，他签</span>
        <!-- 3 认购退款取消  5 签约已退款取消 -->
        <template v-if="detail.extendStatus == 3 || detail.extendStatus == 5">
          <span v-if="detail.extendStatus == 5">已签约</span>
          <span>已退款</span>
          <span>已取消，退款</span>
        </template>
      </div>
      <!-- 其他状态 -->
      <div class="status-progress" v-else>
        <span
          v-for="(item, index) of statusProgress"
          v-show="
            !(
              index !== statusProgress.length - 1 &&
              [2, 6, 16].includes(item.status)
            )
          "
          :class="{
            warning:
              index === statusProgress.length - 1 &&
              item.status !== 20 &&
              item.status !== 10
          }"
          :key="item.status"
          >{{ item.text }}</span
        >
        <span v-if="detail.isActive === false" class="danger">已作废</span>
        <span v-else-if="detail.status === 1" style="color: #999">未回款</span>
      </div>
    </div>
    <div class="info-cell">
      <h3 class="cell-title">房源信息</h3>
      <div class="cell-box">
        <div class="cell">
          <p class="label">楼盘/房号</p>
          <h2>{{ houseInfo.estateName }} {{ houseInfo.number }}</h2>
          <p class="sub">
            建面 {{ houseInfo.buildingSurface }}㎡
            <span v-if="houseInfo.buildingInsideSurface"
              >套内 {{ houseInfo.buildingInsideSurface }}㎡</span
            >
          </p>
        </div>
        <div class="cell">
          <p>
            <span class="label">计划收款额：</span>
            <span
              >¥{{ houseInfo.salesPrice | toThousands }}，单价{{
                houseInfo.unitSalesPrice | toThousands
              }}元/㎡</span
            >
          </p>
          <p>
            <span class="label">控制收款额：</span>
            <span
              >¥{{ houseInfo.salesReservePrice | toThousands }}，单价{{
                houseInfo.unitSalesReservePrice | toThousands
              }}元/㎡</span
            >
          </p>
          <p>
            <span class="label">备案价格：</span>
            <span
              >¥{{ houseInfo.recordPrice | toThousands }}，单价{{
                houseInfo.unitRecordPrice | toThousands
              }}元/㎡</span
            >
          </p>
        </div>
        <div class="cell">
          <p>
            <span class="label">批次地块：</span>
            <span v-if="houseInfo.batch || houseInfo.massif">{{
              [houseInfo.batch, houseInfo.massif] | link("，")
            }}</span>
            <span v-else>-</span>
          </p>
          <p>
            <span class="label">楼栋单元：</span>
            <span v-if="houseInfo.building || houseInfo.unit"
              >{{ houseInfo.building
              }}<span v-if="houseInfo.unit">，{{ houseInfo.unit }}单元</span>
            </span>
            <span v-else>-</span>
          </p>
          <p>
            <span class="label">楼层房号：</span>
            <span>{{
              [houseInfo.floor, houseInfo.roomNumber] | link("，")
            }}</span>
          </p>
        </div>
      </div>
      <!-- • 前置条件：待签约，即签约后不支持更新房源；
          • 更新房源后认购签约中所有测算数据都需要同步更新 -->
      <!-- <el-button
        v-if="detail.status === 1"
        class="add-btn"
        type="info"
        @click="editHouseVisible = true"
        >更新房源</el-button
      > -->
    </div>
    <div class="info-cell" v-if="detail.status === 1">
      <h3 class="cell-title">
        认购信息<span
          class="red"
          v-if="
            detail.status === 1 &&
              orderInfo.overdueDay &&
              orderInfo.overdueDay <= 2
          "
        >
          <i class="el-icon-warning"></i>
          <strong v-if="orderInfo.overdueDay >= 0"
            >计划签约还有{{ orderInfo.overdueDay }}天</strong
          >
          <strong v-else
            >计划签约已超期{{ orderInfo.overdueDay * -1 }}天</strong
          >
        </span>
      </h3>
      <div class="cell-box">
        <div class="cell">
          <p class="label">认购总额</p>
          <h2>¥{{ orderInfo.totalAmount | toThousands }}</h2>
          <!-- <p class="sub">单价{{ orderInfo.unitPrice | toThousands }}元/㎡</p> -->
        </div>
        <div class="cell">
          <p>
            <span class="label">应付定金：</span>
            <span class="orange"
              >¥{{ orderInfo.depositAmount | toThousands }}</span
            >
          </p>
          <p>
            <span class="label">认购置业顾问：</span>
            <span>{{ orderInfo.customerAuthName }}</span>
          </p>
          <p>
            <span class="label">认购日期：</span>
            <span>{{ orderInfo.orderDate }}</span>
          </p>
          <p :class="{ red: detail.status === 1 && orderInfo.overdueDay < 0 }">
            <span class="label">计划签约日期： </span>
            <span>{{ orderInfo.signDate }}</span>
          </p>
        </div>
        <div class="cell">
          <p>
            <span class="label">优惠点数：</span>
            <span>{{ orderInfo.discountRate }}</span>
          </p>
          <p v-if="orderInfo.commissionAmount">
            <span class="label">折佣：</span>
            <span>{{ orderInfo.commissionAmount | toThousands }}</span>
          </p>
          <p>
            <span class="label">溢价：</span>
            <span :class="{ red: orderInfo.overflowAmount < 0 }">{{
              orderInfo.overflowAmount | toThousands
            }}</span>
          </p>
          <p>
            <span class="label">总溢价：</span>
            <span :class="{ red: orderInfo.overflowTotalAmount < 0 }"
              >{{ orderInfo.overflowTotalAmount | toThousands }}
            </span>
          </p>
          <p>
            <span class="label">溢价率： </span>
            <span :class="{ red: orderInfo.overflowTotalRate < 0 }"
              >{{ $calc.accMulti(orderInfo.overflowTotalRate, 100) }}%</span
            >
          </p>
        </div>
      </div>
      <!-- <el-button
        class="add-btn"
        type="info"
        @click="editSubscriptionVisible = true"
        >更新认购</el-button
      > -->
    </div>
    <div class="info-cell" v-if="signInfo">
      <h3 class="cell-title">签约信息</h3>
      <div class="cell-box">
        <div class="cell">
          <p class="label">成交总额</p>
          <h2 class="orange">¥{{ signInfo.totalAmount | toThousands }}</h2>
          <!-- <p class="sub">单价{{ signInfo.unitPrice | toThousands }}元/㎡</p> -->
        </div>
        <div class="cell">
          <p>
            <span class="label">签约置业顾问：</span>
            <span>{{ signInfo.customerAuthName }}</span>
          </p>
          <p>
            <span class="label">签约日期：</span>
            <span>{{ signInfo.orderDate }}</span>
          </p>
          <br />
          <p>
            <span class="label">认购总额：</span>
            <span
              >¥{{ orderInfo.totalAmount | toThousands }}
              <!-- 单价{{ orderInfo.unitPrice | toThousands }}元/㎡ -->
            </span>
          </p>
          <p>
            <span class="label">应付定金：</span>
            <span>¥{{ orderInfo.depositAmount | toThousands }}</span>
          </p>
          <p>
            <span class="label">认购置业顾问：</span>
            <span>{{ orderInfo.customerAuthName }}</span>
          </p>
          <p>
            <span class="label">认购日期：</span>
            <span>{{ orderInfo.orderDate }}</span>
          </p>
          <p>
            <span class="label">计划签约日期： </span>
            <span>{{ orderInfo.signDate }}</span>
          </p>
        </div>
        <div class="cell">
          <p>
            <span class="label">优惠点数：</span>
            <span>{{ signInfo.discountRate }}</span>
          </p>
          <p v-if="signInfo.commissionAmount">
            <span class="label">折佣：</span>
            <span>{{ signInfo.commissionAmount | toThousands }}</span>
          </p>
          <p>
            <span class="label">溢价：</span>
            <span :class="{ red: signInfo.overflowAmount < 0 }">{{
              signInfo.overflowAmount | toThousands
            }}</span>
          </p>
          <p>
            <span class="label">总溢价：</span>
            <span :class="{ red: signInfo.overflowTotalAmount < 0 }"
              >{{ signInfo.overflowTotalAmount | toThousands }}
            </span>
          </p>
          <p>
            <span class="label">溢价率： </span>
            <span :class="{ red: signInfo.overflowTotalRate < 0 }"
              >{{ $calc.accMulti(signInfo.overflowTotalRate, 100) }}%</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="info-cell">
      <h3 class="cell-title">客户信息</h3>
      <el-table :data="detail.customerList" border>
        <el-table-column
          label="客户姓名"
          prop="name"
          width="150"
        ></el-table-column>
        <el-table-column label="客户电话" width="200" prop="phone">
        </el-table-column>
        <el-table-column
          label="证件类型"
          prop="cardType"
          width="150"
        ></el-table-column>
        <el-table-column
          label="证件号码"
          prop="cardNo"
          width="250"
        ></el-table-column>
        <el-table-column label="通讯地址" prop="address"></el-table-column>
        <el-table-column label="操作" width="200">
          <template v-slot="{ row }">
            <el-button type="text" @click="showCustomerEdit(row)"
              >编辑</el-button
            >
            <el-button
              v-if="detail.customerList.length > 1"
              type="text"
              class="red"
              @click="delCustomer(row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-button class="add-btn" type="info" @click="showCustomerEdit({})"
        ><i class="el-icon-plus"></i>添加客户</el-button
      >
    </div>
    <div class="info-cell">
      <h3 class="cell-title">更多信息</h3>
      <template v-if="detail.marketList && detail.marketList.length">
        <h4>①、营销活动</h4>
        <el-table class="mt20" border :data="detail.marketList">
          <el-table-column label="奖励对象" prop="inUserName">
            <template v-slot="{ row }">
              {{ row.rewardObj == 0 ? "客户奖励" : "渠道经纪人奖励" }}
            </template>
          </el-table-column>
          <el-table-column
            label="奖励内容"
            prop="rewardContent"
          ></el-table-column>
          <el-table-column label="我司分摊" prop="companyShare">
            <template v-slot="{ row }">
              <span v-if="row.companyShare">{{
                row.companyShare | toThousands
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="渠道分摊" prop="channelShare">
            <template v-slot="{ row }">
              <span v-if="row.channelShare">{{
                row.channelShare | toThousands
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="营销成本" prop="marketingCost">
            <template v-slot="{ row }">
              <strong v-if="row.marketingCost"
                >￥{{ row.marketingCost | toThousands }}</strong
              >
              <span v-else>-</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <h4 class="mt20">
        {{
          detail.marketList && detail.marketList.length ? "②" : "①"
        }}、相关附件
      </h4>
      <f-upload
        class="mt20"
        title="上传附件图片"
        :default-file-list="defaultFileList"
        @on-success="addAttaches"
        @on-remove="deleteAttaches"
      ></f-upload>
      <h4 class="mt20">
        {{
          detail.marketList && detail.marketList.length ? "③" : "②"
        }}、订单备注
      </h4>
      <el-table class="mt20" border :data="detail.remarkList">
        <el-table-column
          label="备注用户"
          prop="inUserName"
          width="150"
        ></el-table-column>
        <el-table-column
          label="备注时间"
          prop="inDate"
          width="250"
        ></el-table-column>
        <el-table-column label="备注内容" prop="remark"></el-table-column>
        <el-table-column label="操作" width="200">
          <template v-slot="{ row }">
            <el-button type="text" class="red" @click="delOrderRemark(row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-input
        style="margin:20px 0;"
        type="textarea"
        rows="4"
        v-model="remark"
        placeholder="请输入备注"
      ></el-input>
      <el-button type="primary" @click="submitRemark">保存备注</el-button>
    </div>
    <div v-cloak>
      <div class="info-cell" v-if="detail.logList && detail.logList.length">
        <h3 class="cell-title">操作记录</h3>
        <el-table border :data="logList">
          <el-table-column label="操作用户" prop="inUserName"></el-table-column>
          <el-table-column label="操作时间" prop="inDate"></el-table-column>
          <el-table-column label="操作内容">
            <template v-slot="{ row }">
              <p class="operated-content">
                {{ row.log.label
                }}<template
                  v-if="row.log.changeItemList && row.log.changeItemList.length"
                  >：<span
                    v-html="
                      row.log.changeItemList.join(',').replace(
                        /(https:[^,]+(jpeg|jpg|png|gif))/g,
                        `<a class='blue' href='$1' target='__black'>
                          <img src='$1' style='display: inline-block;width:50px;height:50px;object-fit:cover' />
                        </a>`
                      )
                    "
                  ></span
                ></template>
              </p>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="detail.logList.length > 5"
          background
          layout="prev, pager, next"
          :total="detail.logList.length"
          :page-size="5"
          @current-change="handlePaginationChange"
        >
        </el-pagination>
      </div>
    </div>
    <edit-customer
      :visible.sync="editCustomerVisible"
      :data="activeCustomer"
    ></edit-customer>
    <edit-house :visible.sync="editHouseVisible"></edit-house>
    <edit-subscription
      :visible.sync="editSubscriptionVisible"
      :order-detail="detail"
    ></edit-subscription>
  </div>
</template>

<script>
import FUpload from "@/components/upload";
import EditCustomer from "../customer/edit";
import EditHouse from "../house/edit";
import EditSubscription from "../subscription/edit";
import {
  addAttaches,
  deleteAttaches,
  deleteRemark,
  addRemark,
  deleteCustomer
} from "@/api/signedOrder";
export default {
  props: ["orderId", "detail"],
  components: {
    FUpload,
    EditCustomer,
    EditHouse,
    EditSubscription
  },
  data() {
    return {
      logList: [],
      activeCustomer: {}, // 当前操作的的客户
      remark: "", //需要提交的备注信息
      editCustomerVisible: false, // 控制编辑客户弹窗
      editHouseVisible: false, // 控制编辑房源弹窗
      editSubscriptionVisible: false, //更新认购
      houseInfo: {}, //房源信息
      orderInfo: {}, //认购信息
      signInfo: {}, //签约信息
      defaultFileList: [] //默认附件列表
    };
  },
  computed: {
    statusProgress() {
      const { status, isActive } = this.detail;
      // 1 发起认购（待签约） 5 已签约（回款中） 10 已回款 （待结佣） 15 结佣中 20 结佣完成
      const progress = {
        1: "发起认购",
        2: "待签约", // 展示用
        5: "已签约",
        6: "回款中", // 展示用
        10: "已回款",
        16: "结佣中", // 展示用
        20: "已结佣"
      };
      const result = [];
      for (let key in progress) {
        // 已完成的状态
        if (key <= status) {
          result.push({
            status: Number(key),
            text: progress[key]
          });
          // 如果是有效单子 添加下一步的状态
        } else if (isActive && status !== 10) {
          result.push({
            status: Number(key),
            text: progress[key]
          });
          break;
        }
      }
      return result;
    }
  },
  watch: {
    detail(res, oldRes) {
      this.houseInfo = res.houseInfo;
      this.orderInfo = res.orderInfo;
      this.signInfo = res.signInfo;
      this.logList = res.logList.slice(0, 5);
      // 如果更新detail的时候 附件发生了变化才更新附件显示列表
      if (JSON.stringify(res.attachs) !== JSON.stringify(oldRes.attachs)) {
        this.defaultFileList = res.attachs.map(item => ({
          url: item.fileUrl,
          id: item.id
        }));
      }
    }
  },
  methods: {
    // 操作记录分页
    handlePaginationChange(pageNum) {
      this.logList = this.detail.logList.slice((pageNum - 1) * 5, pageNum * 5);
    },
    // 显示客户编辑框
    showCustomerEdit(row) {
      this.editCustomerVisible = true;
      this.activeCustomer = row;
    },
    // 删除客户
    delCustomer(row) {
      this.$confirm("确定要删除该客户吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await deleteCustomer({
            orderId: this.orderId,
            id: row.id
          });
          if (res) {
            this.$showSuccess("删除成功");
            this.$root.$emit("signOrderDetail_refresh");
          }
        })
        .catch(err => {});
    },
    // 获取上传的附件图片数据 添加附件
    addAttaches(fileList) {
      // 防抖
      clearTimeout(this.addAttachTimer);
      this.addAttachTimer = setTimeout(async () => {
        // 筛选本次上传的图片
        const currentList = fileList
          .filter(item => item.status === "ready")
          .map(item => ({
            fileUrl: item.url,
            fileName: item.name || item.url
          }));
        const res = await addAttaches({
          attachList: currentList,
          orderId: this.orderId
        });
        if (res) {
          this.$root.$emit("signOrderDetail_refresh");
        }
      }, 500);
    },
    // 删除附件
    async deleteAttaches(fileList, file) {
      const res = await deleteAttaches(this.orderId, {
        attachIdList: [file.id]
      });
      if (res) {
        this.defaultFileList = res.map(item => ({
          url: item.fileUrl,
          id: item.id
        }));
      }
    },
    // 删除备注
    async delOrderRemark({ id }) {
      const res = await deleteRemark({ id });
      if (res) {
        this.$root.$emit("signOrderDetail_refresh");
      }
    },
    // 提交备注
    async submitRemark() {
      if (!this.remark.trim()) {
        this.$showError("请输入备注");
        return;
      }
      const res = await addRemark({
        reamk: this.remark,
        orderId: this.orderId
      });
      if (res) {
        this.$root.$emit("signOrderDetail_refresh");
        this.remark = "";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.red {
  color: $red !important;
  * {
    color: $red !important;
  }
}
::v-deep {
  .el-upload-list__item {
    transition: none;
  }
}
.status-progress {
  font-weight: normal;
  &.invalid {
    span {
      color: #888;
    }
  }
  span {
    color: $success;
    &.warning {
      color: $warning !important;
    }
    &.danger {
      color: $error !important;
    }
    & + span {
      position: relative;
      padding-left: 40px;
      &:before {
        content: ">";
        font-family: "simsun";
        color: #999;
        position: absolute;
        left: 15px;
        top: 0px;
      }
    }
  }
}
.cell-title {
  span {
    padding-left: 10px;
    font-size: 14px;
    color: $red;
  }
}
.cell-box {
  display: flex;
  padding: 30px 0;
  background: #f8f8f8;
  .cell {
    padding: 0 30px;
    flex: 1;
    & + .cell {
      border-left: 1px solid #eee;
    }
    &:first-child {
      .label {
        color: #999;
      }
      h2 {
        font-size: 30px;
        padding: 20px 0;
        text-align: center;
      }
      .sub {
        text-align: center;
        color: #999;
      }
    }
    &:not(:first-child) {
      p {
        padding: 5px 0;
        @include flex-b;
        .label {
          color: #999;
        }
      }
    }
  }
}
.add-btn {
  margin-top: 20px;
  &.el-button--info {
    color: $primary;
    background-color: #eee;
    border-color: #eee;
    &:active {
      background-color: #ddd;
    }
  }
}
.operated-content {
  @include flex-yc;
}
</style>
