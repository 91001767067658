<template>
  <div class="txt" v-if="data.transactionPrice">
    <p>
      优惠点数：
      <span>{{
        $calc.division(data.transactionPrice, data.salesPrice) | keepDecimal
      }}</span>
    </p>
    <p>
      溢价：
      <span
        :class="{
          red: $calc.numSub(data.transactionPrice, data.salesReservePrice) < 0
        }"
        >{{
          $calc.numSub(data.transactionPrice, data.salesReservePrice)
            | keepDecimal
            | toThousands
        }}</span
      >
    </p>
    <p>
      总溢价：
      <span
        :class="{
          red: $calc.numSub(data.transactionPrice, data.recordPrice) < 0
        }"
        >{{
          $calc.numSub(data.transactionPrice, data.recordPrice)
            | keepDecimal
            | toThousands
        }}</span
      >
    </p>
    <p>
      溢价率：
      <span
        >{{
          $calc.accMulti(
            $calc.division(
              $calc.numSub(data.transactionPrice, data.recordPrice),
              data.transactionPrice
            ),
            100
          ) | keepDecimal
        }}%</span
      >
    </p>
  </div>
</template>

<script>
/**
 * @根据认购总额销售总价和备案总价自动计算以下字段值
 * @优惠点数 = 认购总额 / 销售总价
 * @溢价 = 认购总额 － 控制收款额，负数时显示为红色
 * @总溢价 =  认购总额 － 备案总价，负数时显示为红色
 * @溢价率 = 总溢价 / 认购总额 * 100%
 * */
import { keepDecimal } from "@/utils/calc";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        transactionPrice: 0, //认购总额
        salesPrice: 0, // 控制收款额
        recordPrice: 0, // 备案总价
        salesReservePrice: 0 // 计划收款额
      })
    }
  },
  filters: {
    keepDecimal
  },
  data() {
    return {};
  },
  created() {},
  methods: {}
};
</script>

<style scoped lang="scss">
.txt {
  p {
    text-align: right;
    color: #999;
    span {
      color: #333;
      &.red {
        color: $red;
      }
    }
  }
}
</style>
