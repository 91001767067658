var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-cell"
  }, [_c('div', {
    staticClass: "head"
  }, [_vm._v("渠道成本")]), _c('div', {
    staticClass: "info-cell"
  }, [_vm.hasChannel ? _c('div', {
    staticClass: "base-info"
  }, [_c('div', [_c('p', [_vm._v(" 结佣渠道："), _c('strong', [_vm._v(_vm._s(_vm.commissionDetail.channelName))])]), _vm.commissionDetail.status ? _c('p', [_vm._v(" 结佣状态： "), _c('span', {
    class: {
      orange: _vm.commissionDetail.status == 2,
      green: _vm.commissionDetail.status == 3
    }
  }, [_vm._v(_vm._s(_vm.commissionDetail.statusName))])]) : _vm._e(), _c('p')]), _c('div', {
    staticClass: "mb20"
  }, [_c('p', [_vm._v(" 经纪人姓名："), _c('span', [_vm._v(_vm._s(_vm.commissionDetail.agentName))])]), _c('p', [_vm._v(" 经纪人电话："), _c('span', [_vm._v(_vm._s(_vm._f("formatPhone")(_vm.commissionDetail.agentPhone)))])]), _vm.commissionDetail.reportCode ? _c('p', [_vm._v(" 报备带看："), _c('span', [_vm._v(_vm._s(_vm.commissionDetail.reportCode))]), _c('el-button', {
    staticClass: "gray-text-btn",
    attrs: {
      "type": "text"
    },
    on: {
      "click": _vm.showReportDetail
    }
  }, [_vm._v("查看")])], 1) : _vm._e()])]) : _vm._e(), _vm.commissionDetail.id || _vm.commissionDetail.marketingReward ? _c('el-table', {
    staticClass: "commission-table",
    attrs: {
      "data": _vm.commissionDetail.commissionList,
      "border": ""
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "类型",
      "prop": "type",
      "width": "150"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "结佣点位",
      "width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c('span', [_vm._v(_vm._s(row.point))])];
      }
    }], null, false, 2867809160)
  }), _c('el-table-column', {
    attrs: {
      "label": "应结金额",
      "width": "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c('span', [_vm._v(_vm._s(_vm._f("toThousands")(row.commission)))])];
      }
    }], null, false, 460841798)
  }), _c('el-table-column', {
    attrs: {
      "label": "折佣金额",
      "width": "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c('span', [_vm._v(_vm._s(_vm._f("toThousands")(row.disCommission)))])];
      }
    }], null, false, 4025956856)
  }), _c('el-table-column', {
    attrs: {
      "label": "渠道分摊",
      "width": "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c('span', [_vm._v(_vm._s(_vm._f("toThousands")(row.marketingAllocation)))])];
      }
    }], null, false, 1584353279)
  }), _c('el-table-column', {
    attrs: {
      "label": "\u5408\u8BA1".concat(_vm.commissionDetail.status == 1 ? '（预）' : ''),
      "width": "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c('span', {
          staticClass: "orange"
        }, [_vm._v("¥" + _vm._s(_vm._f("toThousands")(row.sumCommission)))])];
      }
    }], null, false, 4098152565)
  }), _c('el-table-column', {
    attrs: {
      "label": "备注说明"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c('span', [_vm._v(_vm._s(row.note))])];
      }
    }], null, false, 541981332)
  })], 1) : _vm._e(), _vm.logList.length ? _c('el-table', {
    staticClass: "joint-table",
    attrs: {
      "data": _vm.logList,
      "border": ""
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "操作人",
      "width": "150",
      "prop": "inUserName"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "操作时间",
      "width": "200",
      "prop": "inDate"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "操作内容"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(row.log.label)), row.log.changeItemList && row.log.changeItemList.length ? _c('span', [_vm._v(":" + _vm._s(_vm._f("link")(row.log.changeItemList)))]) : _vm._e()];
      }
    }], null, false, 1163449040)
  })], 1) : _vm._e(), _vm.commissionDetail.id || _vm.commissionDetail.marketingReward ? _c('div', {
    staticClass: "table-summary"
  }, [_c('p', [_vm._v(" 合计结佣"), _vm.commissionDetail.status == 1 ? [_vm._v("（预）")] : _vm._e(), _vm._v(" ："), _c('span', {
    staticClass: "orange"
  }, [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.commissionDetail.totalMergeCommission || 0)))])], 2), _c('p', [_vm._v(" 其中，渠道佣金："), _c('span', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.commissionDetail.channelCommission)))])]), _c('p', [_vm._v(" 营销奖励："), _c('span', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.commissionDetail.marketingReward || 0)))])]), _vm.status == 20 ? _c('p', [_vm._v(" 结佣日期："), _c('span', [_vm._v(_vm._s(_vm.commissionDetail.inDate))])]) : _vm._e()]) : _vm._e(), _vm.commissionDetail.status == 1 ? _c('div', {
    staticClass: "tip mt10"
  }, [_vm._v(" 预：表示当前结佣数据为预计结佣，仅作为参考，最终以实际申请结佣数据为准； ")]) : _vm._e(), _c('div', {
    staticClass: "btn-group"
  }, [_vm.status < 10 || _vm.status == 15 ? _c('el-button', {
    staticClass: "blue",
    attrs: {
      "type": "info"
    },
    on: {
      "click": _vm.showPaymentDailog
    }
  }, [_vm._v(" " + _vm._s(!_vm.commissionDetail.status ? "创建预结佣" : _vm.commissionDetail.status == 1 ? "更新预结佣" : "更新佣金") + " ")]) : _vm._e(), _vm.commissionDetail.id ? _c('el-button', {
    attrs: {
      "type": "info"
    },
    on: {
      "click": _vm.deleteCommissionPayment
    }
  }, [_vm._v(" 删除佣金 ")]) : _vm._e()], 1)], 1), _c('report-detail', {
    attrs: {
      "visible": _vm.reportDetailVisible,
      "report-id": _vm.activeReportId
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.reportDetailVisible = $event;
      }
    }
  }), _c('edit-channel-payment', {
    attrs: {
      "visible": _vm.editChannelPaymentVisible,
      "status": _vm.status,
      "order-id": _vm.orderId,
      "commission-detail": _vm.commissionDetail
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editChannelPaymentVisible = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }