<template>
  <div class="container-cell">
    <div class="head">收/退款记录</div>
    <div class="info-cell" v-if="receiptPlanList.length">
      <h3 class="cell-title">
        回款计划
      </h3>
      <p class="tips" v-if="receiptSumInfo.houseRemindNames.length">
        <span class="label">回款提醒对象：</span
        >{{ receiptSumInfo.houseRemindNames.join("、") }}
      </p>
      <el-table
        :data="receiptPlanList"
        border
        :row-class-name="tableRowClassName"
      >
        <el-table-column label="回款状态">
          <template v-slot="{ row }">
            <p v-if="row.paymentStatus === 3">已过期</p>
            <p class="green" v-else-if="row.paymentStatus === 2">已回款</p>
            <p class="orange" v-else-if="row.paymentCount">回款中</p>
            <p class="red" v-else>未回款</p>
          </template>
        </el-table-column>
        <el-table-column label="类型" prop="orderType">
          <template v-slot="{ row }">
            {{ row.payItemName }}
          </template>
        </el-table-column>
        <el-table-column label="期数" prop="period"></el-table-column>
        <el-table-column label="收方">
          <template v-slot="{ row }">
            {{ row.payeeName || "-" }}
          </template>
        </el-table-column>
        <el-table-column label="计划回款金额">
          <template v-slot="{ row }">
            ¥{{ row.totalAmount | toThousands }}
          </template>
        </el-table-column>
        <el-table-column label="已收金额">
          <template v-slot="{ row }">
            <p class="orange" v-if="row.payedAmount">
              ¥{{ row.payedAmount | toThousands }}
            </p>
            <p v-else>-</p>
          </template>
        </el-table-column>
        <el-table-column label="未收金额">
          <template v-slot="{ row }">
            <p class="red" v-if="row.unPayedAmount">
              ¥{{ row.unPayedAmount | toThousands }}
            </p>
            <p v-else>-</p>
          </template>
        </el-table-column>
        <el-table-column label="计划回款日期">
          <template v-slot="{ row }">
            {{ row.orderDate || "-" }}
          </template>
        </el-table-column>
        <el-table-column label="全额回款日期">
          <template v-slot="{ row }">
            {{ row.payDate || "-" }}
          </template>
        </el-table-column>
      </el-table>
      <div class="table-statistics" v-if="orderStatus !== 1">
        <div class="table-statistics gray">
          <p>
            <span class="label">应收金额：</span>¥{{
              receiptSumInfo.totalAmount | toThousands
            }}
          </p>
          <p v-if="receiptSumInfo.unCollectionAmount">
            <span class="label">未收金额：</span
            ><span class="red"
              >¥{{ receiptSumInfo.unCollectionAmount | toThousands }}</span
            >
          </p>
          <p>
            <span class="label">收款进度：</span
            >{{ receiptSumInfo.collectionCal }}
          </p>
          <p v-if="receiptSumInfo.collectionAmount">
            <span class="label">已收金额：</span>
            <strong
              >¥{{ receiptSumInfo.collectionAmount | toThousands }}</strong
            >
          </p>
        </div>
      </div>
    </div>
    <div class="info-cell" v-if="receiptRecordList.length">
      <h3 class="cell-title">
        收/退款记录
      </h3>
      <p class="tips" v-if="collectionInfo">
        <span class="label">回款提醒对象：</span>{{ depositData.remindObject }}
      </p>
      <el-table
        :data="receiptRecordList"
        :row-class-name="tableRowClassName"
        @sort-change="sortChange"
        class="order-merge-table mt15"
      >
        <el-table-column
          width="55"
          align="center"
          prop="settTypeName"
          label="类型"
        >
        </el-table-column>
        <el-table-column width="80" align="center" label="状态">
          <template v-slot="{ row }">
            <template v-if="row.settType == 0">
              <!-- 收款 -->
              <span v-if="row.orderType == 1 && row.transfered">
                转为{{ row.transferTo === 1 ? "定金" : "房款" }}
              </span>
              <p v-else :style="{ color: statusColorMap[row.orderState] }">
                {{ row.orderState == 0 ? "待确认" : "已确认" }}
              </p>
            </template>
            <template v-if="row.settType == 1">
              <!-- 退款 -->
              已退款
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="receiptNo"
          label="收/退单号"
          width="150"
          align="center"
        >
          <template v-slot="{ row }">
            <el-button type="text" @click="showReceiptDetail(row)">{{
              row.receiptNo
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="houseNumber" label="房源编号" align="center">
          <template v-slot="{ row }">
            {{ row.houseNumber }}
          </template>
        </el-table-column>
        <el-table-column label="收/退款信息" align="center">
          <el-table-column
            label="类型"
            prop="orderType"
            align="center"
            class-name="sub-title"
          >
            <template v-slot="{ row }">
              {{ row.payItemName }}
            </template>
          </el-table-column>
          <el-table-column
            prop="payeeType"
            label="收/退方"
            align="center"
            class-name="sub-title"
          >
            <template v-slot="{ row }">
              {{ row.payeeName }}
            </template>
          </el-table-column>
          <el-table-column
            prop="paymentTypeName"
            label="支付方式"
            align="center"
            class-name="sub-title"
          >
            <template v-slot="{ row }">
              <span v-if="row.paymentType === 1">
                POS机<span v-if="row.payWayInfo"
                  >/{{ row.payWayInfo.name }}</span
                >
              </span>
              <span v-else>{{ row.paymentTypeName || "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="paymentTypeName"
            label="收/退账户"
            align="center"
            class-name="sub-title"
          >
            <template v-slot="{ row }">
              <template v-if="row.payWayInfo">
                <span v-if="row.payWayInfo.payMethod == 0">
                  {{ row.payWayInfo.name }}
                </span>
                <span v-else-if="row.payWayInfo.payMethod == 1">
                  终端号{{ row.payWayInfo.clientNo }}
                </span>
                <span v-else>-</span>
              </template>
              <template v-else>-</template>
            </template>
          </el-table-column>
          <el-table-column
            prop="paymentTransactionNo"
            label="交易单号"
            align="center"
            class-name="sub-title"
          >
            <template v-slot="{ row }">
              <span>{{ row.paymentTransactionNo || "-" }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="totalAmount"
          label="收/退金额"
          sortable="custom"
          align="center"
        >
          <template v-slot="{ row }">
            <span
              :class="row.settType == 1 ? 'red' : 'orange'"
              :style="{
                textDecoration:
                  row.orderType == 1 && row.transfered ? 'line-through' : 'none'
              }"
            >
              <span v-if="row.settType == 1">- </span>¥{{
                row.totalAmount | toThousands
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="paymentDate"
          label="收/退日期"
          sortable="custom"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="scope">
            <!--“已确认”，“定金”，“转入房款”，显示“转为房款”，隐藏所有操作按钮-->
            <span v-if="scope.row.orderType == 1 && scope.row.transfered">
              -
            </span>
            <template v-else>
              <!--“未确认”，显示【确认】-->
              <el-link
                type="primary"
                class="mr10"
                @click="confirmReceipt(scope.row)"
                v-if="scope.row.orderState == 0"
              >
                确认
              </el-link>
              <!--“未确认”，隐藏【编辑】-->
              <el-link
                v-if="scope.row.orderState !== 0"
                type="primary"
                class="mr10"
                @click="showEditReceipt(scope.row)"
                >编辑</el-link
              >
              <!--“未确认”，非“定金转入”，隐藏【删除】-->
              <el-link
                v-if="
                  !(scope.row.orderState == 0 && scope.row.paymentType !== 5)
                "
                type="danger"
                @click="
                  scope.row.settType == 0
                    ? deleteReceipt(scope.row)
                    : deleteRefund(scope.row.id)
                "
              >
                删除
              </el-link>
              <!--“未确认”，非“定金转入”，显示【更多】-->
              <el-dropdown
                v-if="scope.row.orderState == 0 && scope.row.paymentType !== 5"
                placement="top-start"
                @command="moreAction($event, scope.row)"
                :ref="'drop-' + scope.$index"
              >
                <el-link type="primary">更多</el-link>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="showEditReceipt">
                    编辑
                  </el-dropdown-item>
                  <el-dropdown-item command="deleteReceipt">
                    删除
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-statistics" v-if="orderStatus !== 1">
        <div class="table-statistics">
          <p>
            <span class="label">待确认收款：</span
            ><span>¥{{ receiptSumInfo.unConfirmAmount | toThousands }}</span>
          </p>
          <p>
            <span class="label ">已确认收款：</span>
            <span>¥{{ receiptSumInfo.confirmAmount | toThousands }}</span>
          </p>
          <p v-if="receiptSumInfo.refundAmount">
            <span class="label">已退款金额：</span
            ><span class="red"
              >-¥{{ receiptSumInfo.refundAmount | toThousands }}</span
            >
          </p>
          <p class="refund-summary" v-if="refundFull">
            已全额退款
          </p>
          <p class="receipt-summary" v-else>
            合计实际收款
            <strong class="orange">
              ¥{{
                (receiptSumInfo.totalActualAmount || 0) | toThousands
              }}</strong
            >， 其中我司
            <strong> ¥{{ receiptSumInfo.companyAmount | toThousands }}</strong>
          </p>
        </div>
      </div>
    </div>
    <div class="info-cell" v-if="logList.length">
      <h3 class="cell-title">操作记录</h3>
      <el-table :data="currentPageLogList" border>
        <el-table-column
          label="操作人"
          width="150"
          prop="inUserName"
        ></el-table-column>
        <el-table-column
          label="操作时间"
          width="200"
          prop="inDate"
        ></el-table-column>
        <el-table-column label="操作内容">
          <template v-slot="{ row }">
            {{ row.log.label
            }}<span
              v-if="row.log.changeItemList && row.log.changeItemList.length"
              >:{{ row.log.changeItemList | link }}</span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="logList.length > 5"
        background
        layout="prev, pager, next"
        :total="logList.length"
        :page-size="5"
        @current-change="handlePaginationChange"
      >
      </el-pagination>
    </div>
    <!-- 编辑收款弹窗 -->
    <edit-company-receipt
      :visible.sync="editCompanyReceiptVisible"
      :order-id="orderId"
      :receipt-id="activeRow.id"
      :success="handleEditSuccess"
    ></edit-company-receipt>
    <!-- 编辑退款弹窗 -->
    <edit-company-refund
      :visible.sync="editCompanyRefundVisible"
      :estateId="estateId"
      :order-id="activeRow.orderId"
      :refund-type="activeRow.orderType == 1 ? '定金' : '房款'"
      :id="activeRow.id"
      :success="handleEditSuccess"
    ></edit-company-refund>
    <financial-detail
      ref="financialDetail"
      :visible.sync="financialDetailVisible"
      :id="activeRow.id"
      :isDetailPage="true"
      @sure="confirmReceipt(activeRow)"
      @del="
        activeRow.settType === 0
          ? deleteReceipt(activeRow)
          : deleteRefund(activeRow.id)
      "
      @edit="showEditReceipt(activeRow)"
    ></financial-detail>
  </div>
</template>

<script>
import {
  getOrderReceiptDetail,
  deleteReceipt,
  financialConfirm,
  financialDel,
  deleteSettlementRefund
} from "@/api/signedOrder";
import EditCompanyReceipt from "./editReceipt";
import EditCompanyRefund from "./editRefund";
import FinancialDetail from "../../../index/_components/financial/_components/financialDetail";
export default {
  components: {
    EditCompanyReceipt,
    EditCompanyRefund,
    FinancialDetail
  },
  props: ["orderId", "logList", "orderStatus", "estateId"],
  data() {
    return {
      queryData: {
        orderBy: "", //1: 收退金额 2：收退日期
        orderByType: "" // 1: 正序 2：倒序
      },
      paymentTypeMap: {
        0: "银行转账",
        1: "POS机刷卡",
        2: "现金支付",
        3: "微信支付",
        4: "支付宝",
        5: "定金转入"
      },
      collectionInfo: null,
      currentPageLogList: [], //前端分页 当前页操作记录列表
      // 回款记录
      receiptRecordList: [],
      // 回款计划
      receiptPlanList: [],
      receiptSumInfo: {
        houseRemindNames: []
      }, //收款汇总信息
      refundFull: false, //是否全额退款
      activeRow: {}, //当前操作的收款记录
      editCompanyReceiptVisible: false, // 控制编辑收款编辑弹窗
      editCompanyRefundVisible: false, // 控制编辑收款退款弹窗
      depositToHousePayment: false, //定金转房款
      financialDetailVisible: false, //控制收款单详情弹窗
      statusColorMap: {
        0: "#f5222d",
        1: "#52c41a",
        2: "#999999"
      }
    };
  },
  created() {
    this.getOrderReceiptDetail();
  },
  watch: {
    logList: {
      immediate: true,
      handler(arr) {
        this.currentPageLogList = arr.slice(0, 5);
      }
    }
  },
  methods: {
    // 更多操作，编辑 删除
    moreAction(fnName, row) {
      this[fnName](row);
    },
    // 刷新
    refresh() {
      this.getOrderReceiptDetail();
    },
    tableRowClassName({ row }) {
      if (row.status == 1 || row.settType == 1) {
        // 已退款
        return "red-row";
      }
      if (row.orderState === 2 || row.paymentStatus === 3) {
        return "gray-row";
      }
      if (row.orderType === 1 && row.transfered) {
        return "gray-row";
      }
      return "";
    },
    // 操作记录分页
    handlePaginationChange(pageNum) {
      this.currentPageLogList = this.logList.slice(
        (pageNum - 1) * 5,
        pageNum * 5
      );
    },
    // 获取收款数据
    async getOrderReceiptDetail() {
      const res = await getOrderReceiptDetail({
        orderId: this.orderId,
        ...this.queryData
      });
      if (res) {
        this.receiptRecordList = res.collectionInfo;
        this.receiptPlanList = res.planInfo;
        // 定金已转房款
        this.receiptSumInfo = res.countInfo;
        // 是否全额退款
        this.refundFull = res.refundFull;
      }
    },
    sortChange({ prop, order }) {
      // orderBy: total_amount: 收退金额, payment_date: 收退日期
      // orderByType: ASC: 正序, DESC: 倒序
      const orderByMaps = {
        totalAmount: "total_amount",
        paymentDate: "payment_date"
      };
      const orderByTypeMaps = {
        ascending: "ASC",
        descending: "DESC"
      };
      this.queryData.orderBy = orderByMaps[prop];
      this.queryData.orderByType = orderByTypeMaps[order];
      this.refresh();
    },
    // 显示收款详情弹窗
    showReceiptDetail(row) {
      this.activeRow = row;
      this.financialDetailVisible = true;
    },
    // 显示收款记录编辑弹窗
    showEditReceipt(row) {
      this.activeRow = row;
      if (row.settType == 0) {
        this.editCompanyReceiptVisible = true;
      } else {
        this.editCompanyRefundVisible = true;
      }
    },
    // 确认收款
    confirmReceipt({ id }) {
      this.$confirm(`请确认是否完成财务对账，执行确认收款？`, "确认收款", {
        type: "warning"
      })
        .then(async () => {
          const res = await financialConfirm({ ids: [id] });
          if (res) {
            this.$showSuccess("操作成功");
            this.getOrderReceiptDetail();
            this.$root.$emit("signOrderDetail_refresh", {
              refreshTypes: ["detail", "log"]
            });
            if (this.financialDetailVisible && this.activeRow.id) {
              this.$refs.financialDetail.getFinancialDetail(this.activeRow.id); // 刷新收款单详情
            }
          }
        })
        .catch(() => {});
    },
    // 删除收款
    deleteReceipt({ id }) {
      this.$confirm(`删除后无法恢复收款记录，确定要删除？`, "删除收款", {
        type: "warning",
        confirmButtonClass: "el-button--danger"
      })
        .then(async () => {
          const res = await financialDel({ ids: [id] });
          if (res) {
            this.$showSuccess("操作成功");
            this.getOrderReceiptDetail();
            this.$root.$emit("signOrderDetail_refresh", {
              refreshTypes: ["detail", "log"]
            });
            this.financialDetailVisible = false; // 删除成功后，关闭收款单详情drawer
          }
        })
        .catch(() => {});
    },
    // 删除退款
    async deleteRefund(id) {
      this.$confirm(`删除后无法恢复，确定要删除？`, "删除退款", {
        type: "warning",
        confirmButtonClass: "el-button--danger"
      })
        .then(async () => {
          const res = await deleteSettlementRefund({ id });
          if (res) {
            this.$showSuccess("操作成功");
            this.getOrderReceiptDetail();
            this.$root.$emit("signOrderDetail_refresh", {
              refreshTypes: ["detail", "log"]
            });
            this.financialDetailVisible = false; // 删除成功后，关闭收款单详情drawer
          }
        })
        .catch(() => {});
    },
    // 编辑收款回调
    handleEditSuccess() {
      this.refresh();
      if (this.financialDetailVisible && this.activeRow.id) {
        this.$refs.financialDetail.getFinancialDetail(this.activeRow.id); // 刷新收款单详情
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .order-merge-table {
  th > .cell {
    padding-left: 0;
  }
  thead.is-group th {
    background-color: #f2f2f2;
    &.sub-title {
      background-color: rgba(247, 247, 254, 1);
      font-weight: normal;
    }
  }
}
.cell-title {
  span {
    padding-left: 10px;
    font-size: 14px;
    color: $red;
  }
}
.tips {
  .label {
    color: #999;
  }
}
.table-statistics {
  padding-top: 10px;
  @include flex-xe2;
  p {
    padding: 3px 0;
    width: 200px;
    color: #333;
    @include flex-b;
    .label {
      color: #999;
    }
    strong {
      font-size: 18px;
      color: $orange;
    }
    &.receipt-summary {
      width: 100%;
      color: #999;
      strong {
        color: #333;
        padding-left: 10px;
        &.orange {
          color: $orange;
        }
      }
    }
    &.refund-summary {
      width: 100%;
      font-size: 18px;
      color: $red;
      text-align: right;
      justify-content: flex-end;
    }
  }

  &.gray {
    p {
      color: #999;
    }
  }
}
.table-cell {
  strong {
    display: block;
    font-size: 18px;
    padding-bottom: 10px;
    &.red {
      color: $red;
    }
    &.orange {
      color: $orange;
    }
  }
  p {
    padding: 3px 0;
    .label {
      color: #999;
    }
    &.red {
      color: $red;
      .label {
        color: $red;
      }
    }
    &.orange {
      color: $orange;
    }
    &.green {
      color: #52c41a;
    }
  }
}
.gray-text-btn {
  color: #999;
}
::v-deep {
  .el-table {
    .gray-row {
      * {
        color: #999 !important;
      }
    }
    .red-row {
      background-color: #fff3f3;
    }
  }
}
.singed-order-detail .container-cell .el-table th {
  background: red !important;
}
</style>
