var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "singed-order-detail"
  }, [_c('base-info', {
    attrs: {
      "detail": _vm.detail,
      "order-id": _vm.orderId
    }
  }), _c('company-account', {
    ref: "companyAccount",
    attrs: {
      "order-id": _vm.orderId,
      "estate-id": _vm.detail.estateId,
      "order-status": _vm.detail.status,
      "log-list": _vm.logsData.paymentLogs || []
    }
  }), _vm.detail.hasChannel || _vm.detail.marketList && _vm.detail.marketList.length ? _c('channel-account', {
    ref: "channelAccount",
    attrs: {
      "status": _vm.detail.status,
      "has-channel": _vm.detail.hasChannel,
      "order-id": _vm.orderId,
      "detail": _vm.detail,
      "log-list": _vm.logsData.commissionLogs || [],
      "commission-detail": _vm.commissionDetail
    }
  }) : _vm._e(), _vm.detail.status === 10 && _vm.detail.hasChannel || _vm.detail.status === 15 || _vm.detail.status === 1 ? _c('div', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "actions"
  }, [_vm.detail.status === 1 ? _c('el-button', {
    attrs: {
      "type": "info"
    },
    on: {
      "click": _vm.abolishOrder
    }
  }, [_vm._v("订单作废")]) : _vm._e(), _vm.detail.status === 10 && _vm.detail.hasChannel ? _c('el-button', {
    attrs: {
      "type": "danger"
    },
    on: {
      "click": function click($event) {
        _vm.editChannelPaymentVisible = true;
      }
    }
  }, [_vm._v("申请结佣")]) : _vm._e(), _vm.detail.status === 15 ? _c('el-button', {
    attrs: {
      "type": "danger"
    },
    on: {
      "click": function click($event) {
        _vm.editCommissionSettlementVisible = true;
      }
    }
  }, [_vm._v("创建结佣")]) : _vm._e()], 1)]) : _vm._e(), _c('edit-company-receipt', {
    attrs: {
      "visible": _vm.editCompanyReceiptVisible,
      "order-id": _vm.orderId,
      "amount-type": _vm.detail.status === 1 ? 1 : 2
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editCompanyReceiptVisible = $event;
      }
    }
  }), _c('edit-channel-payment', {
    attrs: {
      "visible": _vm.editChannelPaymentVisible,
      "order-id": _vm.orderId,
      "detail": _vm.detail,
      "commission-detail": _vm.commissionDetail
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editChannelPaymentVisible = $event;
      }
    }
  }), _c('edit-commission-settlement', {
    attrs: {
      "visible": _vm.editCommissionSettlementVisible,
      "id": _vm.commissionDetail.id,
      "estate-id": _vm.detail.estateId
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editCommissionSettlementVisible = $event;
      }
    }
  }), _c('confirm-channel-payment', {
    attrs: {
      "visible": _vm.confirmChannelPaymentVisible,
      "order-id": _vm.orderId
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.confirmChannelPaymentVisible = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }