var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    staticClass: "f-dialog",
    attrs: {
      "close-on-click-modal": false,
      "title": _vm.data && _vm.data.id ? '编辑客户' : '新增客户',
      "width": "538px"
    },
    on: {
      "open": _vm.handleOpen
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    staticClass: "edit-customer",
    attrs: {
      "model": _vm.formData,
      "label-width": "100px",
      "size": "small"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "客户姓名",
      "prop": "name",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "客户电话",
      "prop": "phone",
      "rules": [_vm.$formRules.required(), _vm.$formRules.tel]
    }
  }, [_c('el-input', {
    attrs: {
      "maxlength": "11",
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "phone", $$v);
      },
      expression: "formData.phone"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "证件类型",
      "prop": "cardType"
    }
  }, [_c('el-select', {
    model: {
      value: _vm.formData.cardType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "cardType", $$v);
      },
      expression: "formData.cardType"
    }
  }, _vm._l(_vm.certificateTypes, function (item) {
    return _c('el-option', {
      key: item,
      attrs: {
        "value": item
      }
    }, [_vm._v(_vm._s(item))]);
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "证件号码",
      "prop": "cardNo"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.cardNo,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "cardNo", $$v);
      },
      expression: "formData.cardNo"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "通讯地址",
      "prop": "address"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.address,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "address", $$v);
      },
      expression: "formData.address"
    }
  })], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }