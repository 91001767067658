var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.data.transactionPrice ? _c('div', {
    staticClass: "txt"
  }, [_c('p', [_vm._v(" 优惠点数： "), _c('span', [_vm._v(_vm._s(_vm._f("keepDecimal")(_vm.$calc.division(_vm.data.transactionPrice, _vm.data.salesPrice))))])]), _c('p', [_vm._v(" 溢价： "), _c('span', {
    class: {
      red: _vm.$calc.numSub(_vm.data.transactionPrice, _vm.data.salesReservePrice) < 0
    }
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm._f("keepDecimal")(_vm.$calc.numSub(_vm.data.transactionPrice, _vm.data.salesReservePrice)))))])]), _c('p', [_vm._v(" 总溢价： "), _c('span', {
    class: {
      red: _vm.$calc.numSub(_vm.data.transactionPrice, _vm.data.recordPrice) < 0
    }
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm._f("keepDecimal")(_vm.$calc.numSub(_vm.data.transactionPrice, _vm.data.recordPrice)))))])]), _c('p', [_vm._v(" 溢价率： "), _c('span', [_vm._v(_vm._s(_vm._f("keepDecimal")(_vm.$calc.accMulti(_vm.$calc.division(_vm.$calc.numSub(_vm.data.transactionPrice, _vm.data.recordPrice), _vm.data.transactionPrice), 100))) + "%")])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }