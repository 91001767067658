var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    staticClass: "f-dialog",
    attrs: {
      "close-on-click-modal": false,
      "title": _vm.title,
      "width": "538px"
    },
    on: {
      "open": _vm.handleOpen
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    staticClass: "edit-receipt",
    attrs: {
      "model": _vm.formData,
      "label-width": "100px",
      "size": "small"
    }
  }, [_c('div', {
    staticClass: "form-cell"
  }, [_c('div', {
    staticClass: "form-cell-title",
    staticStyle: {
      "padding-top": "0"
    }
  }, [_vm._v("渠道信息")]), _c('el-form-item', {
    attrs: {
      "label": "报备带看"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "remote": "",
      "reserve-keyword": "",
      "placeholder": "客户名称、电话后四位",
      "remote-method": _vm.getReportList,
      "loading": _vm.reportQueryLoading
    },
    on: {
      "change": _vm.handleReportChange
    },
    model: {
      value: _vm.formData.reportId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "reportId", $$v);
      },
      expression: "formData.reportId"
    }
  }, _vm._l(_vm.reportList, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.customerName + ' ' + item.customerTelephone,
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.customerName) + " " + _vm._s(item.customerTelephone) + " ")]);
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "合作渠道",
      "prop": "channelName",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "disabled": !!_vm.formData.reportId
    },
    model: {
      value: _vm.formData.channelName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "channelName", $$v);
      },
      expression: "formData.channelName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "经纪人姓名",
      "prop": "agentName",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.agentName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "agentName", $$v);
      },
      expression: "formData.agentName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "经纪人电话",
      "prop": "agentPhone",
      "rules": [_vm.$formRules.required(), _vm.$formRules.tel]
    }
  }, [_c('el-input', {
    attrs: {
      "maxlength": "11",
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.agentPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "agentPhone", $$v);
      },
      expression: "formData.agentPhone"
    }
  })], 1)], 1), _c('div', {
    staticClass: "form-cell"
  }, [_c('div', {
    staticClass: "form-cell-title"
  }, [_c('p', [_vm._v("基础佣金")]), _c('p', {
    staticClass: "sub"
  }, [_vm._v(" 合计结佣"), _vm.formData.status == null && _vm.status < 10 || _vm.formData.status == 1 && _vm.status != 10 ? [_vm._v("（预）")] : _vm._e(), _c('span', [_vm._v("¥"), _c('strong', [_vm._v(_vm._s(_vm.$calc.numSub(_vm.$calc.accAdd(_vm.formData.baseCommission || 0, _vm.$calc.numSub(_vm.formData.baseCashAward || 0, _vm.formData.disCommission || 0)), _vm.formData.marketingAllocation) || 0))])])], 2)]), _c('el-form-item', {
    attrs: {
      "label": "结佣点位",
      "prop": "basePoints",
      "rules": [_vm.$formRules.required(), _vm.$formRules.m0m100p2]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.basePoints,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "basePoints", $$v);
      },
      expression: "formData.basePoints"
    }
  }, [_c('span', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("%")])])], 1), _c('el-form-item', {
    attrs: {
      "label": "应结佣金",
      "prop": "baseCommission",
      "rules": [_vm.$formRules.required(), _vm.$formRules.m0m89p2]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    on: {
      "input": function input($event) {
        return _vm.handlePointInput($event, 'basePoints');
      }
    },
    model: {
      value: _vm.formData.baseCommission,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "baseCommission", $$v);
      },
      expression: "formData.baseCommission"
    }
  }, [_c('span', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("元")])])], 1), _c('el-form-item', {
    attrs: {
      "label": "现金奖",
      "prop": "baseCashAward",
      "rules": [_vm.$formRules.required(), _vm.$formRules.m0m89p2]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.baseCashAward,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "baseCashAward", $$v);
      },
      expression: "formData.baseCashAward"
    }
  }, [_c('span', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("元")])])], 1), _c('el-form-item', {
    attrs: {
      "label": "折佣",
      "prop": "disCommission",
      "rules": [_vm.$formRules.required(), _vm.$formRules.m0m89p2]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.disCommission,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "disCommission", $$v);
      },
      expression: "formData.disCommission"
    }
  }, [_c('span', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("元")])])], 1), _c('el-form-item', {
    attrs: {
      "label": "营销分摊",
      "prop": "marketingAllocation"
    }
  }, [_c('el-input', {
    attrs: {
      "disabled": ""
    },
    model: {
      value: _vm.formData.marketingAllocation,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "marketingAllocation", $$v);
      },
      expression: "formData.marketingAllocation"
    }
  }, [_c('span', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("元")])])], 1)], 1), _c('div', {
    staticClass: "form-cell"
  }, [_c('div', {
    staticClass: "form-cell-title"
  }, [_c('p', [_vm._v("额外佣金")]), _vm.formData.extCommission ? _c('p', {
    staticClass: "sub"
  }, [_vm._v(" 合计额外佣金"), _vm.formData.status == null && _vm.status < 10 || _vm.formData.status == 1 && _vm.status != 10 ? [_vm._v("（预）")] : _vm._e(), _c('span', [_vm._v("¥"), _c('strong', [_vm._v(_vm._s(_vm.$calc.accAdd(_vm.formData.extCommission, _vm.formData.extCashAward || 0) || 0))])])], 2) : _vm._e()]), _c('el-form-item', {
    attrs: {
      "label": "结佣点位",
      "rules": [_vm.$formRules.m0m89p2]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.extPoints,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "extPoints", $$v);
      },
      expression: "formData.extPoints"
    }
  }, [_c('span', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("%")])])], 1), _c('el-form-item', {
    attrs: {
      "label": "应结佣金",
      "rules": [_vm.$formRules.m0m89p2]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    on: {
      "input": function input($event) {
        return _vm.handlePointInput($event, 'extPoints');
      }
    },
    model: {
      value: _vm.formData.extCommission,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "extCommission", $$v);
      },
      expression: "formData.extCommission"
    }
  }, [_c('span', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("元")])])], 1), _c('el-form-item', {
    attrs: {
      "label": "现金奖",
      "rules": [_vm.$formRules.m0m89p2]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.extCashAward,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "extCashAward", $$v);
      },
      expression: "formData.extCashAward"
    }
  }, [_c('span', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("元")])])], 1), _c('el-form-item', {
    attrs: {
      "label": "其他奖励",
      "prop": "extOtherCommission"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.extOtherCommission,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "extOtherCommission", $$v);
      },
      expression: "formData.extOtherCommission"
    }
  }, [_c('span', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("元")])])], 1)], 1), _c('div', {
    staticClass: "form-cell"
  }, [_c('div', {
    staticClass: "form-cell-title"
  }, [_c('p', [_vm._v("更多信息")])]), _c('el-form-item', {
    attrs: {
      "label": "结佣日期",
      "prop": "commissionDate",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "date",
      "placeholder": "选择日期",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.formData.commissionDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "commissionDate", $$v);
      },
      expression: "formData.commissionDate"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "备注说明",
      "prop": "note"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea"
    },
    model: {
      value: _vm.formData.note,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "note", $$v);
      },
      expression: "formData.note"
    }
  })], 1)], 1)]), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v("保存")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }