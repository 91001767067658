var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-cell"
  }, [_c('div', {
    staticClass: "head"
  }, [_vm._v("结算：甲方房款")]), _c('div', {
    staticClass: "info-cell"
  }, [_vm._m(0), _c('el-table', {
    attrs: {
      "data": _vm.list,
      "border": ""
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "付款方"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "付款单号"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "付款金额"
    }
  }, [[_c('p', [_vm._v("¥797,590")])]], 2), _c('el-table-column', {
    attrs: {
      "label": "备注说明"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "状态"
    }
  }, [[_c('span', [_vm._v("待确认")])]], 2)], 1), _vm._m(1)], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-info"
  }, [_c('p', [_vm._v("备案价格："), _c('strong', [_vm._v("¥1,595,180")])]), _c('div', [_c('p', [_vm._v("已付金额："), _c('span', {
    staticClass: "orange"
  }, [_vm._v("¥797,590")])]), _c('p', [_vm._v("未付金额："), _c('span', {
    staticClass: "red"
  }, [_vm._v("¥797,590")])]), _c('p', [_vm._v("结算进度："), _c('span', [_vm._v("已结50% ")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-summary"
  }, [_c('p', [_vm._v("合计结款："), _c('span', {
    staticClass: "red"
  }, [_vm._v("¥1,595,180")])]), _c('p', [_vm._v("最后结款日期："), _c('span', [_vm._v("2021-10-27")])])]);

}]

export { render, staticRenderFns }